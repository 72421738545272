//menu couleur
export default [
    {'ref' :'1', 'hex' : '#ffd900'},
    {'ref' :'11', 'hex' : '#f18d00'},
    {'ref' :'16', 'hex' : '#d4021e'},
    {'ref' :'37', 'hex' : '#f4beae'},
    {'ref' :'51', 'hex' : '#dc7f9f'},
    {'ref' :'62', 'hex' : '#856195'},
    {'ref' :'89', 'hex' : '#1788bc'},
    {'ref' :'110', 'hex' : '#468f9c'},
    {'ref' :'117', 'hex' : '#54a79c'},
    {'ref' :'131', 'hex' : '#77a479'},
    {'ref' :'148', 'hex' : '#96b922'},
    {'ref' :'182', 'hex' : '#d79a00'},
    {'ref' :'185', 'hex' : '#a47a4e'},
    {'ref' :'210', 'hex' : '#707574'},
    {'ref' :'221', 'hex' : '#000000'},
    {'ref' :'231', 'hex' : '#ffffff'},
    {'ref' :'232', 'hex' : '#83f52c'},
  
  ]

  