import { closePropositions } from "./fonctions.js";
import { cloneColors } from "./fonctions.js";
import { hexToRgbSlice } from "./colorsConvert.js";



/**
 *  Fonction qui compare les couleurs similiaires par rapport a une autre couleur
 * @param {*} colorObjects 
 * @param {*} colorToCompare 
 * @returns 
 */

function compareColors(colorObjects, colorToCompare) {
  // Liste des couleurs similaires ou de teinte proche
  const similarColors = [];

  // Pour chaque couleur dans le tableau
  for (const color of colorObjects) {
    // Récupération des valeurs RGB de la couleur à comparer et de la couleur en cours de traitement
    const colorToCompareRGB = hexToRgbSlice(colorToCompare);
    const currentColorRGB = hexToRgbSlice(color.hex);

    // Calcul de la distance entre les couleurs en utilisant la formule de distance euclidienne en 3 dimensions
    // (pour chaque composante RGB)
    const distance = Math.sqrt(
      Math.pow(colorToCompareRGB.r - currentColorRGB.r, 2) +
      Math.pow(colorToCompareRGB.g - currentColorRGB.g, 2) +
      Math.pow(colorToCompareRGB.b - currentColorRGB.b, 2)
    );

    // Si la distance est inférieure à 50 (arbitrairement choisi), alors la couleur en cours de traitement
    // est considérée comme similaire ou de teinte proche
    if (distance < 25) {
      similarColors.push(color);
    }
  }

  return similarColors;

}



/**
 *  Fonction affiches les couleurs similaires pour chaque couleurs avec un formulaire
 * @param {*} arrayNuancier 
 */

function colorsSimilares(arrayNuancier, arrayImgLogoMini) {
  const soloForms = document.querySelectorAll('.solo-search-form');

   
  // Utilisation de forEach pour parcourir les éléments de la collection
  soloForms.forEach(form => {
    form.addEventListener('submit', (e) => {
      // Empêcher la soumission du formulaire
      e.preventDefault();
      
      //effacer toutes les palettes deja existante
   const deletePalette = document.querySelectorAll('.sectionSimilaire');
   for (let i = 0; i < deletePalette.length; i++) {
     deletePalette[i].remove();
   }

   const deletePalette2 = document.querySelectorAll('.sectionPalette');
   for (let i = 0; i < deletePalette2.length; i++) {
     deletePalette2[i].remove();
   }

   const deletePalette3 = document.querySelectorAll('.sectionInfos');
   for (let i = 0; i < deletePalette3.length; i++) {
    deletePalette3[i].remove();
   }


      // Récupération de la valeur sélectionnée par l'utilisateur
      const colorToCompare = form.querySelector('input[name=solo-search-hex]').value;
      const colorToCompareRef = form.querySelector('input[name=solo-search-ref]').value;

      function scrollTo(hash) {
        location.hash = "#" + hash;
      }

      scrollTo(colorToCompareRef);


      // Utilisation de la fonction compareColors() pour trouver les couleurs similaires ou de teinte proche
      const similarColors = compareColors(arrayNuancier, colorToCompare);


      // Recuperer l'image Logo Mini du nuancier - bug parcel
      const arrayLogoNuancierMini = arrayImgLogoMini;
      const resultLogoMini = (brand) => {
        const brandImg = arrayLogoNuancierMini.filter((item) => item.nuancier === brand);
        return brandImg[0] ? brandImg[0].img : null;
      };


      // Génération du contenu HTML à partir des objets du tableau similarColors
      const insertHtml = similarColors.map(obj => {
        const backgroundColor = `background-color:${obj.hex}`;
        const colorBrand = `${obj.marque}`;
        const brandImg = resultLogoMini(colorBrand);
        const brandTitle = `${obj.marque} - ${obj.name}`;
        const colorName = `${obj.name}`;
        const colorRef = `${obj.ref}`;
        const colorHex = `${obj.hex}`;
        const inputId = `${obj.ref}-input`;

        return `
          <div style="${backgroundColor}" class="color-box item">
            <span class="imgMarque"><img src="${brandImg}" title="Selectionner ${brandTitle}"></span>
            <div class="info">
              <h3>${colorName}</h3>
              <p>${colorBrand} · ${colorRef} · ${colorHex}</p>
            </div>
            <div class="quantity">
              <input value="" type="number" class="quantite" name="qte[]" min="0" placeholder="0" id="${inputId}">
            </div>
            <button class="btnApp btnAdd addListeSimilar" title="Selectionner la couleur" v-on:click="cloneItem"></button>
            <button class="btnApp btnDrag handle"></button>
            <button class="btnApp btnSupprimer" title="Supprimer"></button>
          </div>
        `;
      }).join('');


      // Affichage des resultats sous l'element seletionné

      const parentDiv = form.parentNode.parentNode;

      // Create the new section element
      const newDiv = document.createElement('section');
      newDiv.innerHTML = insertHtml;
      newDiv.classList.add('sectionSimilaire', 'draggable', 'animate__animated', 'animate__fadeIn');
      newDiv.setAttribute('id', 'draggable')
      newDiv.style.display = 'block';


      // Create the new h2 element
      const newTitle = document.createElement('h2');
      newTitle.innerHTML = 'Couleurs Similaires<button class=" btnApp btncloseSimilar"></button>';
      newTitle.classList.add('titleSimilare', 'animate__animated', 'animate__fadeIn');

      // Add the new elements to the DOM
      parentDiv.insertAdjacentElement('afterend', newDiv);
      newDiv.insertAdjacentElement('afterbegin', newTitle);



      // Récupérer la section contenant les éléments similaires
      const sectionSimilaires = document.querySelectorAll('#block-colors .sectionSimilaire');
      // Récupérer tous les éléments ayant la classe "closeSimilar"
      const closeBtns = document.querySelectorAll('#block-colors .btncloseSimilar');
      // Cacher la section contenant les éléments similaires - import
      closePropositions(sectionSimilaires, closeBtns);



      // fonction pour cloner les itmes similaires - selections
      const cloneZone = document.querySelector('#print');
      const addListe = document.querySelectorAll('.addListeSimilar');
      cloneColors(addListe, cloneZone);

    });
  });
}





/*
// Fonction pour générer et afficher le formulaire de recherche de couleurs
function showColorSearchForm() {
// Création du formulaire
const form = document.createElement('form');
form.setAttribute('class', 'color-search-form');

// Ajout d'un élément de formulaire pour sélectionner la couleur à comparer
const colorInput = document.createElement('input');
colorInput.setAttribute('type', 'color');
colorInput.setAttribute('name', 'color-to-compare');
colorInput.setAttribute('value', '#59a8cd');
form.appendChild(colorInput);

// Ajout d'un bouton pour soumettre le formulaire
const submitButton = document.createElement('button');
submitButton.setAttribute('type', 'submit');
submitButton.setAttribute('class', 'button-16');
submitButton.innerHTML = 'Couleurs similaires';
form.appendChild(submitButton);

// Ajout du formulaire à la page HTML
const divCompare = document.getElementById('colorCompare');
divCompare.appendChild(form);

// Gestion de la soumission du formulaire
form.addEventListener('submit', (e) => {
  // Empêcher la soumission du formulaire
  e.preventDefault();


  // Récupération de la valeur sélectionnée par l'utilisateur
  const colorToCompare = form.querySelector('input[name=color-to-compare]').value;

  // Utilisation de la fonction compareColors() pour trouver les couleurs similaires ou de teinte proche
  const similarColors = compareColors(colorObjects, colorToCompare);

  // Affichage des couleurs trouvées
  console.log('similarColors 2: ', similarColors);
});



}
// Exemple d'utilisation de la fonction showColorSearchForm()
showColorSearchForm();
*/



export { compareColors, colorsSimilares };