export default [

  {
    "ref": "RV-222",
    "hex": "#FFE885"
  },
  {
    "ref": "RV-102",
    "hex": "#FF6F22"
  },
  {
    "ref": "RV-322",
    "hex": "#d60530"
  },
  {
    "ref": "RV-323",
    "hex": "#F5AFC5"
  },
  {
    "ref": "RV-325",
    "hex": "#d071aa"
  },
  {
    "ref": "RV-321",
    "hex": "#a194c5"
  },
  {
    "ref": "RV-326",
    "hex": "#01abd8"
  },
  {
    "ref": "RV-327",
    "hex": "#0182cc"
  },
  {
    "ref": "RV-336",
    "hex": "#245BA7"
  },
  {
    "ref": "RV-341",
    "hex": "#06B5D2"
  },
  {
    "ref": "RV-254",
    "hex": "#57B798"
  },
  {
    "ref": "RV-235",
    "hex": "#b9ce12"
  },
  {
    "ref": "RV-344",
    "hex": "#BEC39E"
  },
  {
    "ref": "RV-331",
    "hex": "#A5AAA8"
  },
  {
    "ref": "RV-Fluo-1",
    "hex": "#FF0093"
  },
  {
    "ref": "RV-Carbon",
    "hex": "#000000"
  },
  {
    "ref": "RV-Silver",
    "hex": "#826107"
  },

]