export default [
  {
    "hex": "#FFEE9B",
    "name": "Vanilla",
    "ref": "GLD400-1000",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#ffe363",
    "name": "Easter Yellow",
    "ref": "GLD400-1010",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#FFD900",
    "name": "Citrus",
    "ref": "GLD400-1020",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#EABF00",
    "name": "Banana",
    "ref": "GLD400-1030",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#E5BF00",
    "name": "Asia",
    "ref": "GLD400-1040",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#C6A018",
    "name": "Curry",
    "ref": "GLD400-1050",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#A98715",
    "name": "Mustard",
    "ref": "GLD400-1060",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#7E6430",
    "name": "Everglade",
    "ref": "GLD400-1080",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#FDF07A",
    "name": "Butta",
    "ref": "GLD400-1100",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#F4E600",
    "name": "Brimstone",
    "ref": "GLD400-1110",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#DDCF1E",
    "name": "Banana Joe",
    "ref": "GLD400-1120",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#BBB62F",
    "name": "Pepperoni Mild",
    "ref": "GLD400-1130",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#8B812F",
    "name": "Pepperoni Hot",
    "ref": "GLD400-1140",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#625E25",
    "name": "Reed",
    "ref": "GLD400-1150",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#46432B",
    "name": "Nato",
    "ref": "GLD400-1170",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#252A22",
    "name": "Military Green",
    "ref": "GLD400-1180",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#FFDD70",
    "name": "Pudding",
    "ref": "GLD400-1200",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#FECC33",
    "name": "Yellow Submarine",
    "ref": "GLD400-1210",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#FBB800",
    "name": "Yellow Cab",
    "ref": "GLD400-1220",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#F6A000",
    "name": "Yolk",
    "ref": "GLD400-1230",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#EB8904",
    "name": "Golden Yellow",
    "ref": "GLD400-1240",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#C87620",
    "name": "Terra",
    "ref": "GLD400-1250",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#523B1C",
    "name": "Mushroom",
    "ref": "GLD400-1260",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#F7EAD9",
    "name": "Bone",
    "ref": "GLD400-1400",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#F6E2C9",
    "name": "Latte",
    "ref": "GLD400-1410",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#ECC6A8",
    "name": "Cappuccino",
    "ref": "GLD400-1420",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#DAAC92",
    "name": "Make Up",
    "ref": "GLD400-1430",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#CD926E",
    "name": "Toffee",
    "ref": "GLD400-1440",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#885845",
    "name": "Hot Chocolate",
    "ref": "GLD400-1450",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#6E4E34",
    "name": "Palish Brown",
    "ref": "GLD400-1470",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#FBE9CF",
    "name": "White Orange",
    "ref": "GLD400-2000",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#FEE9D9",
    "name": "Orange Ice",
    "ref": "GLD400-2010",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#F9C173",
    "name": "Creme Orange",
    "ref": "GLD400-2020",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#F4A34A",
    "name": "Blast Orange",
    "ref": "GLD400-2030",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#F29530",
    "name": "Scampi",
    "ref": "GLD400-2040",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#F08217",
    "name": "Orangina",
    "ref": "GLD400-2050",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#EE7412",
    "name": "Capri",
    "ref": "GLD400-2060",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#EC6415",
    "name": "Orange",
    "ref": "GLD400-2070",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#EA5818",
    "name": "Pure Orange",
    "ref": "GLD400-2080",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#E43E1D",
    "name": "Red Orange",
    "ref": "GLD400-2090",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#DC0824",
    "name": "Blood Orange",
    "ref": "GLD400-2095",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#F7BFC1",
    "name": "Lychee",
    "ref": "GLD400-3000",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#ED8798",
    "name": "Bazooka Joe",
    "ref": "GLD400-3010",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#E73C4E",
    "name": "Strawberry",
    "ref": "GLD400-3020",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#D6053E",
    "name": "Raspberry",
    "ref": "GLD400-3030",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#BA1829",
    "name": "Ketchup",
    "ref": "GLD400-3040",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#971C24",
    "name": "Brick",
    "ref": "GLD400-3050",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#721E23",
    "name": "Royal Red",
    "ref": "GLD400-3060",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#5B181C",
    "name": "Purple Red",
    "ref": "GLD400-3070",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#3E1511",
    "name": "Black Red",
    "ref": "GLD400-3080",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#271619",
    "name": "Vampirella",
    "ref": "GLD400-3085",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#F9D2DB",
    "name": "Frozen Strawberry",
    "ref": "GLD400-3100",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#EFA9C2",
    "name": "Frozen Raspberry",
    "ref": "GLD400-3110",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#D84389",
    "name": "Pink Pink",
    "ref": "GLD400-3130",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#8E135F",
    "name": "Cherry Blossom",
    "ref": "GLD400-3150",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#E1C3CC",
    "name": "Babyskin",
    "ref": "GLD400-4000",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#D1A3AD",
    "name": "Mortadella",
    "ref": "GLD400-4010",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#99536A",
    "name": "Dusty Pink",
    "ref": "GLD400-4020",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#824150",
    "name": "Ancient Pink",
    "ref": "GLD400-4030",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#792B3E",
    "name": "Powder Pink",
    "ref": "GLD400-4040",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#D2C1DD",
    "name": "White Lilac",
    "ref": "GLD400-4100",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#A999C6",
    "name": "Light Lilac",
    "ref": "GLD400-4110",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#7C6EAC",
    "name": "Teen Spirit",
    "ref": "GLD400-4130",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#55428A",
    "name": "Lavender",
    "ref": "GLD400-4150",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#39377E",
    "name": "Gonzo",
    "ref": "GLD400-4160",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#2C3068",
    "name": "Blue Velvet",
    "ref": "GLD400-4170",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#172245",
    "name": "Welsh",
    "ref": "GLD400-4180",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#10182A",
    "name": "Cassis",
    "ref": "GLD400-4190",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#CDB7D5",
    "name": "Crocus",
    "ref": "GLD400-4200",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#B08EBD",
    "name": "Viola",
    "ref": "GLD400-4210",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#8E4D91",
    "name": "Sweet Dream",
    "ref": "GLD400-4220",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#6F317F",
    "name": "Valerie",
    "ref": "GLD400-4230",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#522158",
    "name": "Lakers",
    "ref": "GLD400-4240",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#5C3154",
    "name": "Deep Purple",
    "ref": "GLD400-4250",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#321440",
    "name": "Black Purple",
    "ref": "GLD400-4260",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#CCE8F0",
    "name": "Flipper",
    "ref": "GLD400-5000",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#A3D7E8",
    "name": "Fresh Blue",
    "ref": "GLD400-5010",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#6FC8E6",
    "name": "Baby Blue",
    "ref": "GLD400-5020",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#00AFD8",
    "name": "Bermuda",
    "ref": "GLD400-5030",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#00A1D1",
    "name": "Light Blue",
    "ref": "GLD400-5040",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#008CC7",
    "name": "Sky Blue",
    "ref": "GLD400-5050",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#006EAD",
    "name": "Blue Magic",
    "ref": "GLD400-5060",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#005089",
    "name": "Signal Blue",
    "ref": "GLD400-5075",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#003A6F",
    "name": "Ultramarine",
    "ref": "GLD400-5080",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#003457",
    "name": "Blue Note",
    "ref": "GLD400-5085",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#0C2337",
    "name": "Nautilus",
    "ref": "GLD400-5090",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#D8EDED",
    "name": "Polar Blue",
    "ref": "GLD400-5100",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#B9E0EA",
    "name": "Summit",
    "ref": "GLD400-5105",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#8ACADC",
    "name": "Himalaya",
    "ref": "GLD400-5110",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#60A1B8",
    "name": "Mt Everest",
    "ref": "GLD400-5120",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#477283",
    "name": "Mt Fuji",
    "ref": "GLD400-5140",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#255162",
    "name": "Fjord",
    "ref": "GLD400-5150",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#0B353F",
    "name": "Deep Sea",
    "ref": "GLD400-5160",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#062331",
    "name": "Navy",
    "ref": "GLD400-5170",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#E5EECE",
    "name": "Venom",
    "ref": "GLD400-6000",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#C5DDA8",
    "name": "Linden Green",
    "ref": "GLD400-6010",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#A3C96D",
    "name": "Green Apple",
    "ref": "GLD400-6020",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#ADC70B",
    "name": "Lime",
    "ref": "GLD400-6030",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#7EB125",
    "name": "Lawn Green",
    "ref": "GLD400-6040",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#008332",
    "name": "Greenery",
    "ref": "GLD400-6050",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#006030",
    "name": "Fern Green",
    "ref": "GLD400-6060",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#005129",
    "name": "Smaragd Green",
    "ref": "GLD400-6070",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#003B24",
    "name": "Jungle Green",
    "ref": "GLD400-6080",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#00301F",
    "name": "Deep Forest",
    "ref": "GLD400-6090",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#EEF5E3",
    "name": "Liberty",
    "ref": "GLD400-6100",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#AFD8C1",
    "name": "Malachite Light",
    "ref": "GLD400-6120",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#65B79A",
    "name": "Malachite",
    "ref": "GLD400-6130",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#3A8469",
    "name": "Malachite Dark",
    "ref": "GLD400-6150",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#006D58",
    "name": "Pine",
    "ref": "GLD400-6160",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#E5F1E6",
    "name": "Venice",
    "ref": "GLD400-6200",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#C1E2E0",
    "name": "CAN2 Cool Candy",
    "ref": "GLD400-6210",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#93D1D9",
    "name": "Pool",
    "ref": "GLD400-6230",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#50ADC5",
    "name": "Dolphins",
    "ref": "GLD400-6250",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#0086A2",
    "name": "Aqua",
    "ref": "GLD400-6260",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#006275",
    "name": "Reef",
    "ref": "GLD400-6270",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#00363F",
    "name": "Petrol",
    "ref": "GLD400-6280",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#F1EFEA",
    "name": "Pebble",
    "ref": "GLD400-7000",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#E1E2E2",
    "name": "Marble",
    "ref": "GLD400-7010",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#C6C7C6",
    "name": "Wall",
    "ref": "GLD400-7020",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#ACAAA3",
    "name": "Iron Curtain",
    "ref": "GLD400-7030",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#7C7E7C",
    "name": "Roof",
    "ref": "GLD400-7050",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#626969",
    "name": "Gravel",
    "ref": "GLD400-7060",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#313C3F",
    "name": "Stealth",
    "ref": "GLD400-7070",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#0E1514",
    "name": "Coke",
    "ref": "GLD400-7090",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#F2EEE7",
    "name": "Dolomite",
    "ref": "GLD400-7210",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#E8E4DB",
    "name": "Ceramic",
    "ref": "GLD400-7220",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#D5D3CA",
    "name": "Buzzard",
    "ref": "GLD400-7230",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#DAD7C9",
    "name": "Yosemite",
    "ref": "GLD400-7240",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#A09F9A",
    "name": "Apshalt",
    "ref": "GLD400-7250",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#76726B",
    "name": "Meteorite",
    "ref": "GLD400-7260",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#55524D",
    "name": "Basalt",
    "ref": "GLD400-7270",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#F7F0CE",
    "name": "Elm",
    "ref": "GLD400-8000",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#E5D49C",
    "name": "Sahara Yellow",
    "ref": "GLD400-8010",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#DABA7F",
    "name": "Sahara Beige",
    "ref": "GLD400-8020",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#B29260",
    "name": "Duck Season",
    "ref": "GLD400-8040",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#F3A68C",
    "name": "Salmon",
    "ref": "GLD400-8070",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#D78366",
    "name": "Dirty Apricot",
    "ref": "GLD400-8080",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#351E1F",
    "name": "Aubergine",
    "ref": "GLD400-8085",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#B77452",
    "name": "Nougat",
    "ref": "GLD400-8090",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#7D3924",
    "name": "Orange Brown",
    "ref": "GLD400-8100",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#912B2F",
    "name": "Rusto Coat",
    "ref": "GLD400-8110",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#3E161B",
    "name": "Wine Red",
    "ref": "GLD400-8115",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#54311F",
    "name": "Cacao",
    "ref": "GLD400-8120",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#192120",
    "name": "Anthracite",
    "ref": "GLD400-8145",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#353028",
    "name": "Concrete",
    "ref": "GLD400-8150",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#934D54",
    "name": "Lip",
    "ref": "GLD400-8170",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#CBB5BF",
    "name": "Paris",
    "ref": "GLD400-8175",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#D6C4BF",
    "name": "Brain",
    "ref": "GLD400-8180",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#D5BFB9",
    "name": "Flesh",
    "ref": "GLD400-8190",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#F8BDB2",
    "name": "SHRIMP PASTEL",
    "ref": "CLASSIC 2100",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#F29C86",
    "name": "SHRIMP",
    "ref": "CLASSIC 2120",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#EF8464",
    "name": "SHRIMP DARK",
    "ref": "CLASSIC 2130",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#1E2357",
    "name": "LOUIE LILAC",
    "ref": "CLASSIC 4300",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#C9E1F3",
    "name": "DENIM LIGHT",
    "ref": "CLASSIC 5200",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#99C6E3",
    "name": "DENIM",
    "ref": "CLASSIC 5210",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#6598C6",
    "name": "DENIM STONEWASHED",
    "ref": "CLASSIC 5220",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#EEE436",
    "name": "POISON PASTEL",
    "ref": "CLASSIC 6300",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#C8D215",
    "name": "POISON",
    "ref": "CLASSIC 6320",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#ADC70B",
    "name": "POISON DARK",
    "ref": "CLASSIC 6330",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#494F1F",
    "name": "OLIVE GREEN",
    "ref": "CLASSIC 6340",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#A7A97A",
    "name": "MANILA LIGHT",
    "ref": "CLASSIC 6400",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#838663",
    "name": "MANILA GREEN",
    "ref": "CLASSIC 6410",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#46412C",
    "name": "MANILA DARK",
    "ref": "CLASSIC 6420",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#B89247",
    "name": "SAND",
    "ref": "CLASSIC 8300",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#80441E",
    "name": "HAZELNUT",
    "ref": "CLASSIC 8310",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#4E1A1B",
    "name": "CHESTNUT",
    "ref": "CLASSIC 8320",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#FFDE09",
    "name": "Shock Yellow Light",
    "ref": "GLD400-S1000",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#FCBC00",
    "name": "Shock Yellow",
    "ref": "GLD400-S1010",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#EF7D0A",
    "name": "Shock Orange Light",
    "ref": "GLD400-S2000",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#EA5B18",
    "name": "Shock Orange",
    "ref": "GLD400-S2010",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#E6301D",
    "name": "Shock Orange Dark",
    "ref": "GLD400-S2020",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#CB0D1F",
    "name": "Shock Red",
    "ref": "GLD400-S3000",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#AF151A",
    "name": "Shock KENT Blood Red",
    "ref": "GLD400-S3020",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#E888B2",
    "name": "Shock Pink Light",
    "ref": "GLD400-S4000",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#C73475",
    "name": "Shock Pink",
    "ref": "GLD400-S4010",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#312C66",
    "name": "Shock Lilac",
    "ref": "GLD400-S4220",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#7FC5D3",
    "name": "Shock Blue Light",
    "ref": "GLD400-S5000",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#004D91",
    "name": "Shock Blue",
    "ref": "GLD400-S5010",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#05356C",
    "name": "Shock Blue Dark",
    "ref": "GLD400-S5020",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#7EB627",
    "name": "Shock Green Light",
    "ref": "GLD400-S6000",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#007A37",
    "name": "Shock Green",
    "ref": "GLD400-S6010",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#00612F",
    "name": "Shock Green Dark",
    "ref": "GLD400-S6020",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#B1592A",
    "name": "Shock Brown Light",
    "ref": "GLD400-S8000",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#5A3121",
    "name": "Shock Brown",
    "ref": "GLD400-S8010",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#312111",
    "name": "Shock Brown Dark",
    "ref": "GLD400-S8020",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#0C0C0A",
    "name": "Shock Black",
    "ref": "GLD400-S9000",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#FFFFFF",
    "name": "Shock White",
    "ref": "GLD400-S9100",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#F8F5E9",
    "name": "Shock White Cream",
    "ref": "GLD400-S9110",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#FFFFFF",
    "name": "Shock White Pure",
    "ref": "GLD400-S9120",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#FFEE00",
    "name": "100% YELLOW",
    "ref": "GLD400-1000",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#00AEC7",
    "name": "100% CYAN",
    "ref": "GLD400-2000",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  },
  {
    "hex": "#D00065",
    "name": "100% MAGENTA",
    "ref": "GLD400-3000",
    "marque": "montana gold",
    "nuancier": "montanaGold"
  }
]