import { Notifications } from './notifs.js';

function getChildOuterHTML(div) {
  // Initialize an array to store the outerHTML of the children nodes
  const outerHTMLArray = [];

  // Iterate over the children nodes of the div and add their outerHTML to the array
  for (const childNode of div.childNodes) {
    outerHTMLArray.push(childNode.outerHTML);
  }

  // Return the array of outerHTML of the children nodes
  return outerHTMLArray;
}

/**
 * Local storage - Function to save the selection
 * 
 * @param {*} zone 
 * @returns 
 */
function saveStorage(zone) {
  // Vérifie si le navigateur prend en charge le stockage local
  if (typeof localStorage === "undefined") {
    console.log("Erreur : le stockage local n'est pas pris en charge !");
    return;
  }

  // La clé de stockage à utiliser
  const key = "colorStorage";

  // Obtient les éléments HTML enfants de la zone et les joint en une chaîne de caractères
  const itemsToSave = getChildOuterHTML(zone);
  const joinItemsToSave = itemsToSave.join('');

  // Enregistre la chaîne de caractères jointe dans le stockage local
  localStorage.setItem(key, joinItemsToSave);

  // Affiche un message de confirmation dans la console
  console.log("Enregistrement réussi");
}


function saveStorageAuto(zone) {
  // Vérifie si le navigateur prend en charge le stockage local
  if (typeof localStorage === "undefined") {
    console.log("Erreur : le stockage local n'est pas pris en charge !");
    return;
  }

  // La clé de stockage à utiliser
  const key = "colorStorageAuto";

  // Obtient les éléments HTML enfants de la zone et les joint en une chaîne de caractères
  const itemsToSave = getChildOuterHTML(zone);
  const joinItemsToSave = itemsToSave.join('');

  // Enregistre la chaîne de caractères jointe dans le stockage local
  localStorage.setItem(key, joinItemsToSave);

  // Affiche un message de confirmation dans la console
  //console.log("Enregistrement auto réussi");
}


  /**
   * Localstorage - Fonction qui permet de charger la selection
   */
  function loadStorage(zone, cle) {
    if (typeof localStorage === "undefined") {
      console.log("Error: you don't have localStorage!");
      return;
    }
  
    const key = cle;
    const value = localStorage.getItem(key);
    zone.innerHTML = value;
  }
  
  /**
   * Localstorage - Fonction qui permet de supprimer la selection
   */
  function deleteStorage(name) {
    if (typeof localStorage === "undefined") {
      console.log("Error: you don't have localStorage!");
      return;
    }
  
    localStorage.removeItem(name);
    console.log("storage delete");
  }
  
  /**
   * loadStorageAuto - chargement de la sauvegarde si elle existe
   */
  function loadStorageAuto(zone) {
    let storageKey = 'colorStorage';
    
    if (localStorage.getItem(storageKey) !== null) {
      document.body.onload = loadStorage(zone, storageKey);
      const notifLoadStart = new Notifications("Sauveguarde chargée", "#6bb66c");
      //console.log('load sauverguarde classique start' );
    }
  
    if (localStorage.getItem(storageKey) === null) {
      storageKey = 'colorStorageAuto';
      document.body.onload = loadStorage(zone, storageKey);
      //console.log(' load sauveguarde auto start' );
    }
  
    
  }


/**
 * appStorage - mise en place des actions de sauveguarde
 * @param {*} zone 
 */
function appStorage(zone) {
  
    // chargement auto  de la sauvegarde si elle existe
    loadStorageAuto(zone);
  
  
    const btnSave = document.querySelector('#save');
    const btnLoad = document.querySelector('#load');
    const suppStorage = document.querySelector('#suppStorage');
  
  
    btnSave.addEventListener('click', () => {
      const colorAdd = document.querySelectorAll("#print input.quantite").length;
      if (colorAdd === 0) {
        const notifAddColor = new Notifications("Veuillez ajouter au moins une couleur pour sauveguarder", "#d80028");
      } else {
        const notifAddColor = new Notifications("Selection sauvegardée !", "#6bb66c");
        saveStorage(zone, 'colorStorage');
      }
    });
  
  
    btnLoad.addEventListener('click', () => {

      if (localStorage.getItem("colorStorage") != null) {
        loadStorageAuto(zone, 'colorStorage');
        const notifLoad = new Notifications("Sauvegarde chargée", "#59a8cd");
      }
        else if ((localStorage.getItem("colorStorage") === null) && (localStorage.length > 0)){
          loadStorageAuto(zone, 'colorStorageAuto');
          const notifLoad = new Notifications("Sauvegarde auto chargée", "#59a8cd");
      } else {
        const notifLoad = new Notifications("Aucune sauvegarde à charger", "#d80028");
  
      }
    });
  
    suppStorage.addEventListener('click', () => {
      if (localStorage.getItem("colorStorage") != null) {
        const notifSuppStorage = new Notifications("Sauveguarde supprimée", "#6bb66c");
        deleteStorage("colorStorage");
      } 
      if (localStorage.getItem("colorStorageAuto") != null) {
        deleteStorage("colorStorageAuto");
      }
      else {
        const notifSuppStorage = new Notifications("Aucune sauvegarde à supprimer", "#d80028");
      }
    });
    
  }
  




  export { saveStorage, loadStorage, deleteStorage, loadStorageAuto, saveStorageAuto, appStorage};