
/**
 * Convertir une couleur hexadécimale en valeurs RGB (rouge, vert, bleu)
 * Slice - renvoie d'un tableau 
 * @param {*} hex 
 * @returns 
 */
function hexToRgbSlice(hex) {
    // Extraction des valeurs rouge, vert et bleu
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return { r, g, b };
}



/**
* Convertir une couleur hexadécimale en valeurs RGB (rouge, vert, bleu)
* substring - retourne une sous-chaîne de la chaîne courante (string)
* @param {*} hex 
* @returns 
*/
function hexToRgbString(hex) {
    // Extraction des valeurs de couleur rouge, vert et bleu
    let r = parseInt(hex.substring(1, 3), 16);
    let g = parseInt(hex.substring(3, 5), 16);
    let b = parseInt(hex.substring(5, 7), 16);

    // Renvoi des valeurs en rgb
    return [r, g, b];
}



/**
 * Fonction pour convertir des couleurs hexadécimales en cmyk
 * substring - retourne une sous-chaîne de la chaîne courante (string)
 * @param {*} hex 
 * @returns 
 */
function hexToCmykString(hex) {
    // Extraction des valeurs de couleur rouge, vert et bleu
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);
  
    // Conversion en cmyk en utilisant les formules appropriées
    const k = 1 - Math.max(r, g, b) / 255;
    const c = (1 - r / 255 - k) / (1 - k);
    const m = (1 - g / 255 - k) / (1 - k);
    const y = (1 - b / 255 - k) / (1 - k);
  
    // Conversion en pourcentage
    const cmyk = [c, m, y, k].map((val) => Math.round(val * 100));
  
    // Remplacement des valeurs NaN par 0 (probleme avec le noir)
    cmyk.forEach((val, i) => {
      if (isNaN(val)) {
        cmyk[i] = 0;
      }
    });
  
    // Renvoi des valeurs en cmyk
    return cmyk;
  }
  



/**
 * Fonction pour convertir des couleurs RGB en CMYK
 * @param {*} r 
 * @param {*} g 
 * @param {*} b 
 * @returns 
 */
function rgbToCmyk([r, g, b]) {
    // Convert RGB values to CMYK
    var c = 1 - (r / 255);
    var m = 1 - (g / 255);
    var y = 1 - (b / 255);
    var k = 1;

     // Conversion en pourcentage
     c = Math.round(c * 100);
     m = Math.round(m * 100);
     y = Math.round(y * 100);
     k = Math.round(k * 100);

    // Return the CMYK values as an object
    return {
        c: c,
        m: m,
        y: y,
        k: k
    };
}



/**
 * 
 * @param {*} r 
 * @param {*} g 
 * @param {*} b 
 * @returns 
 */
function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export { hexToRgbSlice, hexToRgbString, hexToCmykString, rgbToCmyk };


