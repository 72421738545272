// Function pour redimensionner une div

function resizeDiv(btn, element1, element2) {

    let isResizing = false;
    let startY = 0;
    let startHeight1 = 0;
    let startHeight2 = 0;
    //let startWidth = 0;

    btn.addEventListener('touchstart', (event) => {
        isResizing = true;
        startY = event.touches[0].clientY;
        startHeight1 = element1.clientHeight;
        startHeight2 = element2.clientHeight;

        //startWidth = div.clientWidth;
    });

    document.addEventListener('touchmove', (event) => {
        if (!isResizing) {
            return;
        }

        const deltaY = event.touches[0].clientY - startY;
        element1.style.height = (startHeight1 + deltaY) + 'px';
        element2.style.height = (startHeight2 - deltaY) + 'px';

        
    });
    
    // Désactiver le comportement par défaut de rafraîchissement de page lors du défilement vers le bas
        btn.addEventListener('touchmove', reloadPage, { passive: false });

        function reloadPage(e) {
            e.preventDefault();
        }

    
    document.addEventListener('touchend', (event) => {
        isResizing = false;
        
    });



}

export { resizeDiv };

