export default [
    {'ref' :'RV-108', 'hex' : '#ef8407'},
    {'ref' :'RV-189', 'hex' : '#fedf00'},
    {'ref' :'RV-141', 'hex' : '#bd9b61'},
    {'ref' :'RV-77', 'hex' : '#d52f25'},
    {'ref' :'RV-115', 'hex' : '#ec7faa'},
    {'ref' :'RV-92', 'hex' : '#a06c7e'},
    {'ref' :'RV-321', 'hex' : '#c19bcc'},
    {'ref' :'RV-312', 'hex' : '#85a0d3'},
    {'ref' :'RV-5013', 'hex' : '#0098d2'},
    {'ref' :'RV-146', 'hex' : '#00c6d0'},
    {'ref' :'RV-186', 'hex' : '#a5b700'},
    {'ref' :'RV-266', 'hex' : '#91cc1a'},
    {'ref' :'RV-183', 'hex' : '#86c8b4'},
    {'ref' :'RV-119', 'hex' : '#898b8e'},
    {'ref' :'RV-9011', 'hex' : '#000000'},
    {'ref' :'RV-1013', 'hex' : '#ffffff'},
    {'ref' :'Fluo-7', 'hex' : '#00FE2A'},
  
  ];