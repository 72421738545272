
import { closePropositions } from "./fonctions.js";
import { cloneColors } from "./fonctions.js";




function generateColorPalette(arrayNuanciers, arrayImgLogoMini) {
  const soloPalette = document.querySelectorAll('.palette-search-form');


  // Utilisation de forEach pour parcourir les éléments de la collection
  soloPalette.forEach(form => {
    form.addEventListener('submit', (e) => {
      // Empêcher la soumission du formulaire
      e.preventDefault();


  //effacer toutes les palettes deja existante
   const deletePalette = document.querySelectorAll('.sectionSimilaire');
   for (let i = 0; i < deletePalette.length; i++) {
     deletePalette[i].remove();
   }

   const deletePalette2 = document.querySelectorAll('.sectionPalette');
   for (let i = 0; i < deletePalette2.length; i++) {
     deletePalette2[i].remove();
   }

   const deletePalette3 = document.querySelectorAll('.sectionInfos');
   for (let i = 0; i < deletePalette3.length; i++) {
    deletePalette3[i].remove();
   }

      // la couleur de base a utliser pour créer le nuancier
      const baseColor = form.querySelector('input[name=palette-hex]').value;

      // Les infos de la couleur de base
      const baseColorName = form.querySelector('input[name=palette-name]').value;
      const baseColorRef = form.querySelector('input[name=palette-ref]').value;
      const baseColorMarque = form.querySelector('input[name=palette-marque]').value;
      const baseColorNuancier = form.querySelector('input[name=palette-nuancier]').value;
      const baseColorEntries = new Map([['hex', baseColor], ['name', baseColorName], ['marque', baseColorMarque], ['ref', baseColorRef]]);
      const baseColorInfos = Object.fromEntries(baseColorEntries);

      // Tableau des couleurs hexadécimales à utiliser pour la palette
      const allNuanciers = arrayNuanciers;
      //retrouver toutes les obljets contenat le nom du nuancier
      const colorPalette = allNuanciers.filter(item => item.nuancier === baseColorNuancier);


      //scroll lors de la selection
      function scrollTo(hash) {
        location.hash = "#" + hash;
      }
      scrollTo(baseColorRef);




      // Sélectionnez une couleur complémentaire
      let complementaryColor = baseColor;
      while (complementaryColor == baseColor) {
        let randomIndex = Math.floor(Math.random() * colorPalette.length);
        complementaryColor = colorPalette[randomIndex];
      }

      /* ia
      crée une fonction javascript pour trouver la couleur hexadécimal 
      qui se situent en face de l'autre sur la roue chromatique, 
      à partir d'une liste d'une quinzaine de couleurs héxadecimale qui se trouve dans tableau.
      Chercher le résultat de la couleur complémentaire la plus proche en comparant les couleurs de la liste*/

      // Sélectionnez deux couleurs de teinte similaire
      let similarToneColors = [];
      while (similarToneColors.length < 2) {
        let randomIndex = Math.floor(Math.random() * colorPalette.length);
        let selectedColor = colorPalette[randomIndex];
        // Assurez-vous de ne pas sélectionner la couleur de base ou la couleur complémentaire
        if (selectedColor != baseColor && selectedColor != complementaryColor) {
          similarToneColors.push(selectedColor);
        }
      }

      // Sélectionnez une couleur sombre
      let darkColor = baseColor;
      while (darkColor == baseColor || darkColor == complementaryColor || similarToneColors.indexOf(darkColor) != -1) {
        let randomIndex = Math.floor(Math.random() * colorPalette.length);
        darkColor = colorPalette[randomIndex];
      }

      // Sélectionnez une couleur plus claire
      let lightColor = baseColor;
      while (lightColor == baseColor || lightColor == complementaryColor || similarToneColors.indexOf(lightColor) != -1 || darkColor == lightColor) {
        let randomIndex = Math.floor(Math.random() * colorPalette.length);
        lightColor = colorPalette[randomIndex];
      }

      // Assemblez les couleurs sélectionnées dans un tableau
      let selectedColors = [baseColorInfos, darkColor, complementaryColor, similarToneColors[0], similarToneColors[1], lightColor];

      // Recuperer l'image Logo Mini du nuancier - bug parcel
      const arrayLogoNuancierMini = arrayImgLogoMini;
      const resultLogoMini = (brand) => {
        const brandImg = arrayLogoNuancierMini.filter((item) => item.nuancier === brand);
        return brandImg[0] ? brandImg[0].img : null;
      };

      // Génération du contenu HTML à partir des objets du tableau selectedColors
      const insertHtml = selectedColors.map(obj => {
        let backgroundColor = `background-color:${obj.hex}`;
        let colorBrand = `${obj.marque}`;
        let brandImg = resultLogoMini(colorBrand);
        let brandTitle = `${obj.marque} - ${obj.name}`;
        let colorName = `${obj.name}`;
        let colorRef = `${obj.ref}`;
        let colorHex = `${obj.hex}`;
        let inputId = `${obj.ref}-input`;

        return `
          <div style="${backgroundColor}" class="color-box item">
          <span class="imgMarque"><img src="${brandImg}" title="Selectionner ${brandTitle}"></span>
          <div class="info">
              <h3>${colorName}</h3>
              <p>${colorBrand} · ${colorRef} · ${colorHex}</p>
            </div>
            <div class="quantity">
              <input value="" type="number" class="quantite" name="qte[]" min="0" placeholder="0" id="${inputId}">
            </div>
            <button class="btnApp btnAdd addListePalette" title="Selectionner la couleur" v-on:click="cloneItem"></button>
            <button class="btnApp btnDrag handle"></button>
            <button class="btnApp btnSupprimer" title="Supprimer"></button>
          </div>
        `;
      }).join('');

      //création du contenu
      function createColorPalette(parent, html) {
        // Create the new section element
        const newDiv = document.createElement('section');
        newDiv.innerHTML = html;
        newDiv.classList.add('sectionPalette', 'draggable', 'animate__animated', 'animate__fadeIn');
        newDiv.setAttribute('id', 'draggable');
        newDiv.style.display = 'block';

        // Create the new h2 element
        const newTitle = document.createElement('h2');
        newTitle.innerHTML = 'Palette de couleurs</button><button class=" btnApp btnclosePalette"></button>';
        newTitle.classList.add('titleSimilare', 'animate__animated', 'animate__fadeIn');

        // Add the new elements to the DOM
        parent.insertAdjacentElement('afterend', newDiv);
        newDiv.insertAdjacentElement('afterbegin', newTitle);


      }

      // affichage du contenu
      const parentDiv = form.parentNode.parentNode;
      const html = insertHtml;

      createColorPalette(parentDiv, html);


      // Récupérer la section contenant les éléments similaires
      const sectionSimilaires = document.querySelectorAll('#block-colors .sectionPalette');
      // Récupérer tous les éléments ayant la classe "closeSimilar"
      const closeBtns = document.querySelectorAll('#block-colors .btnclosePalette');
      // Cacher la section contenant les éléments similaires - import
      closePropositions(sectionSimilaires, closeBtns);


      // fonction pour cloner les itmes similaires - selections
      const cloneZone = document.querySelector('#print');
      const addListe = document.querySelectorAll('.addListePalette');
      cloneColors(addListe, cloneZone);

    });
  });


}










export { generateColorPalette };