export default [
  {
    "hex": "#f0e082",
    "name": "vanilla",
    "ref": "FB-100",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#fedd3b",
    "name": "zinc yellow",
    "ref": "FB-102",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#fad820",
    "name": "cadmium yellow",
    "ref": "FB-104",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#f5cc04",
    "name": "signal yellow",
    "ref": "FB-106",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#f6bb00",
    "name": "melon yellow",
    "ref": "FB-110",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#fbbe5c",
    "name": "saffron",
    "ref": "FB-112",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#fbbc41",
    "name": "dahlia",
    "ref": "FB-114",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#f28749",
    "name": "apricot",
    "ref": "FB-210",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#e97300",
    "name": "light orange",
    "ref": "FB-204",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#d65627",
    "name": "orange",
    "ref": "FB-212",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#d3461e",
    "name": "red orange",
    "ref": "FB-214",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#d83331",
    "name": "signal red",
    "ref": "FB-304",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#c03431",
    "name": "fire red",
    "ref": "FB-312",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#852731",
    "name": "ruby red",
    "ref": "FB-306",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#6c142e",
    "name": "burgundy",
    "ref": "FB-320",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#512831",
    "name": "aubergine",
    "ref": "FB-322",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#f1d5b6",
    "name": "cold peach",
    "ref": "FB-208",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#fcb576",
    "name": "peach",
    "ref": "FB-200",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#ff9876",
    "name": "pastel orange",
    "ref": "FB-202",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#ed9cad",
    "name": "piglet pink light",
    "ref": "FB-308",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#e64461",
    "name": "piglet pink",
    "ref": "FB-310",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#bb2038",
    "name": "crazy cherry",
    "ref": "FB-311",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#9c052d",
    "name": "cherry dark",
    "ref": "FB-313",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#e1bdcc",
    "name": "erica pastel",
    "ref": "FB-401",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#dca7c8",
    "name": "erica light",
    "ref": "FB-399",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#dd6a9b",
    "name": "erica violet FB-400",
    "ref": "FB-400",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#d8326d",
    "name": "telemagenta",
    "ref": "FB-402",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#8a1478",
    "name": "traffic purple",
    "ref": "FB-404",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#47233c",
    "name": "traffic purple dark",
    "ref": "FB-318",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#cbd2eb",
    "name": "lavender",
    "ref": "FB-406",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#9466a2",
    "name": "grape",
    "ref": "FB-408",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#6c427c",
    "name": "blackberry",
    "ref": "FB-410",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#4f3159",
    "name": "currant",
    "ref": "FB-412",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#c6cee6",
    "name": "viola pastel",
    "ref": "FB-414",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#b9b6e9",
    "name": "viola light",
    "ref": "FB-416",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#6166a2",
    "name": "viola",
    "ref": "FB-418",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#243c81",
    "name": "viola dark",
    "ref": "FB-420",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#243c81",
    "name": "cosmos blue light",
    "ref": "FB-424",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#374a9c",
    "name": "cosmos blue",
    "ref": "FB-426",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#001f60",
    "name": "cosmos blue dark",
    "ref": "FB-428",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#70c7e7",
    "name": "lighting blue",
    "ref": "FB-502",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#92c5e4",
    "name": "light blue light",
    "ref": "FB-504",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#4497d1",
    "name": "light blue",
    "ref": "FB-508",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#006cb3",
    "name": "sky blue",
    "ref": "FB-510",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#075095",
    "name": "signal blue",
    "ref": "FB-512",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#1b4888",
    "name": "true blue",
    "ref": "FB-514",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#0b2b59",
    "name": "sapphire blue",
    "ref": "FB-522",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#b5d0ee",
    "name": "denim blue pastel",
    "ref": "FB-524",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#93c3e1",
    "name": "denim blue light",
    "ref": "FB-526",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#5089ae",
    "name": "denim blue",
    "ref": "FB-528",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#3d586e",
    "name": "denim blue dark",
    "ref": "FB-530",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#7fa8c5",
    "name": "cream blue light",
    "ref": "FB-516",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#2085c5",
    "name": "cream blue",
    "ref": "FB-518",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#075b8e",
    "name": "cream blue dark",
    "ref": "FB-520",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#8ddecc",
    "name": "aqua pastel",
    "ref": "FB-614",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#3398b6",
    "name": "aqua light",
    "ref": "FB-616",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#004e5a",
    "name": "aqua",
    "ref": "FB-618",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#004c46",
    "name": "aqua dark",
    "ref": "FB-620",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#b0e3e4",
    "name": "riviera light",
    "ref": "FB-600",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#5cc9ba",
    "name": "riviera",
    "ref": "FB-602",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#00a391",
    "name": "lagoon blue",
    "ref": "FB-604",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#007375",
    "name": "ocean blue",
    "ref": "FB-606",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#c1e089",
    "name": "primavera green",
    "ref": "FB-654",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#93b379",
    "name": "tarzan green",
    "ref": "FB-656",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#71744f",
    "name": "camo green",
    "ref": "FB-658",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#21372c",
    "name": "olive",
    "ref": "FB-660",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#c2deba",
    "name": "menthol pastell",
    "ref": "FB-662",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#8be1b1",
    "name": "menthol light",
    "ref": "FB-664",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#6dcb99",
    "name": "menthol",
    "ref": "FB-666",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#004538",
    "name": "menthol dark",
    "ref": "FB-668",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#23a37c",
    "name": "turquoise light",
    "ref": "FB-670",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#008b5a",
    "name": "turquoise",
    "ref": "FB-672",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#216144",
    "name": "turquoise dark",
    "ref": "FB-674",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#d7d43f",
    "name": "pistachio light",
    "ref": "FB-624",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#bac33d",
    "name": "pistachio",
    "ref": "FB-626",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#8aa945",
    "name": "grass green",
    "ref": "FB-628",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#6a9848",
    "name": "fern green",
    "ref": "FB-630",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#4e8746",
    "name": "leaf green",
    "ref": "FB-632",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#3f7337",
    "name": "moss green",
    "ref": "FB-632",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#245c4e",
    "name": "fir green",
    "ref": "FB-636",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#ece819",
    "name": "kiwi pastel",
    "ref": "FB-638",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#bad64a",
    "name": "kiwi light",
    "ref": "FB-640",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#7bbd2a",
    "name": "kiwi",
    "ref": "FB-642",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#649116",
    "name": "kiwi dark",
    "ref": "FB-644",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#eddfb6",
    "name": "ivory light",
    "ref": "FB-702",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#bf9a5c",
    "name": "beige brown",
    "ref": "FB-704",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#9d682f",
    "name": "ocher",
    "ref": "FB-706",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#4c2800",
    "name": "nut",
    "ref": "FB-708",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#2b1104",
    "name": "chocolate",
    "ref": "FB-710",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#f5e4d3",
    "name": "cookie dough pastel",
    "ref": "FB-716",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#d6caa0",
    "name": "cookie dough",
    "ref": "FB-717",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#e6c3ac",
    "name": "macadamia pastel",
    "ref": "FB-718",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#a58876",
    "name": "macadamia",
    "ref": "FB-719",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#b4a485",
    "name": "grey beige light",
    "ref": "FB-732",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#a18b62",
    "name": "grey beige",
    "ref": "FB-734",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#6f5b39",
    "name": "khaki grey",
    "ref": "FB-736",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#3b3024",
    "name": "dark brown",
    "ref": "FB-738",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#c8b8b4",
    "name": "terracotta grey pastel",
    "ref": "FB-808",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#a68a8a",
    "name": "terracotta grey light",
    "ref": "FB-810",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#816a6b",
    "name": "terracotta grey",
    "ref": "FB-812",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#695d6c",
    "name": "violet grey middle",
    "ref": "FB-820",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#56495a",
    "name": "violet grey",
    "ref": "FB-822",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#dad3cc",
    "name": "light grey neutral",
    "ref": "FB-834",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#b0adac",
    "name": "middle grey neutral",
    "ref": "FB-836",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#808080",
    "name": "grey neutral",
    "ref": "FB-838",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#84807f",
    "name": "dark grey neutral",
    "ref": "FB-840",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#515250",
    "name": "anthracite grey middle",
    "ref": "FB-842",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#4a4b4e",
    "name": "anthracite grey",
    "ref": "FB-844",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#363636",
    "name": "anthracite grey dark",
    "ref": "FB-846",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#000000",
    "name": "deep black",
    "ref": "FB-904",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#ffffff",
    "name": "pure white",
    "ref": "FB-900",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#f6e600",
    "name": "fluo yellow",
    "ref": "FB-1000",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#ec6500",
    "name": "fluo orange",
    "ref": "FB-1002",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#e50075",
    "name": "fluo pink",
    "ref": "FB-1004",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#37aa32",
    "name": "fluo green",
    "ref": "FB-1006",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#000000a3",
    "name": "transparent black",
    "ref": "FB-3004",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#ffffff75",
    "name": "transparent white",
    "ref": "FB-3000",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#C0C0C0",
    "name": "ultra chrome",
    "ref": "FB-902",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#FFD700",
    "name": "gold",
    "ref": "FB-906",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  },
  {
    "hex": "#b87333",
    "name": "copper",
    "ref": "FB-908",
    "marque": "flame blue",
    "nuancier": "flameBlue"
  }
]