import { createNewPage, insertNewPageBody, closeNewPage } from "./createNewPage.js";
import { hexToRgbString, hexToCmykString, rgbToCmyk } from "./colorsConvert.js";



// information sur la couleur selectionné
function infosColor(colorInfos) {

    colorInfos.forEach(element => {
        element.addEventListener('submit', (e) => {
            // Empêcher la soumission du formulaire
            e.preventDefault();

            // Récupération des données de la couleur sélectionnée par l'utilisateur
            const colorSelectHex = element.querySelector('input[name=color-infos-hex]').value;
            const colorSelectName = element.querySelector('input[name=color-infos-name]').value;
            const colorSelectRef = element.querySelector('input[name=color-infos-ref]').value;
            const colorSelectMarque = element.querySelector('input[name=color-infos-marque]').value;
            const colorSelectLogo = element.querySelector('input[name=color-infos-logo]').value;

            let colorSelectRgb = hexToRgbString(colorSelectHex); // [255, 0, 0] en rgb
           // let colorSelectCmyk = hexToCmykString(colorSelectHex); // [0, 1, 1, 0] en cmyk
            //let colorSelectCmyk = rgbToCmyk(colorSelectRgb); // [255, 0, 0] en rgb



            const contenu = document.createElement('div');
            contenu.innerHTML = `
        <div style="background-color:${colorSelectHex}" class="colorWindow"></div>
        <div class="colorWindowInfos">
        <h1>${colorSelectName}</h1>
          <div class="colonne-1"><img src="${colorSelectLogo}"/></div>
          <div class="colonne-2">
            <h3><b>${colorSelectMarque}</b></h3>
            <h3><b>REF:</b> ${colorSelectRef}</h3>
            <h3><b>HEX:</b> ${colorSelectHex} </h3>
            <h3><b>RGB:</b> ${colorSelectRgb} </h3>
          </div>
        </div>`;

            //insertion du contenu dans la nouvelle page
            const page = createNewPage(contenu);
            // insertion de la page dans le body
            insertNewPageBody(page);
            //fermeture de la page
            const buttonCloses = document.querySelectorAll('.btnClosePage');
            closeNewPage(buttonCloses, page)




        })
    })
}

export { infosColor };