import { Notifications } from './notifs.js';
import html2canvas from 'html2canvas';

/**
 * Export JPG
 * 
 * @param {*} zone 
 */

function exportJpg(zone) {
  // Sélection de l'élément HTML avec l'ID "jpger"
  const exportJPG = document.querySelector("#jpger");

  function exportCanvas() {
    // Notification indiquant le chargement de l'image
    const notifExport = new Notifications("Chargement de l'image...", "#59a8cd");

    // Sélection de l'élément modal de capture d'écran
    const captureModal = document.querySelector("#capture");
    // Sélection de l'élément où l'image sera affichée
    const suppCapture = document.querySelector("#blockCapture");
    // Création d'un nom de fichier en utilisant la date actuelle
    const dateName = new Date().toISOString().slice(0, 10);

    // Masquage des boutons de déplacement et de suppression des couleurs
    document.querySelectorAll("#print .btnDrag").forEach((btn) => {
      btn.style.display = "none";
    });
    document.querySelectorAll("#print .btnSupprimer").forEach((btn) => {
      btn.style.display = "none";
    });


    // Suppression de l'ancienne image
    suppCapture.innerHTML = "";
    // Affichage de la fenêtre de capture
    captureModal.style.display = "block";

    // Création d'un canvas à partir de la zone en utilisant html2canvas
    html2canvas(zone, {
      width: zone.scrollWidth,
      height: zone.scrollHeight,
    }).then((canvas) => {
      // Récupération des données du canvas au format JPEG
      const canvasData = canvas.toDataURL();
      // Création d'un lien de téléchargement
      const a = document.createElement("a");
      a.innerHTML =
        "<div class='downloadLink'>Télécharger</div>";
      a.download = "endtoend-color-" + dateName + ".jpeg";
      a.href = canvasData;
      const divLink = document.createElement("div");
      divLink.appendChild(a);
      // Ajout de l'image et du lien dans la fenêtre de capture
      document.getElementById("blockCapture").innerHTML =
        "<img src='" + canvasData + "' title='endtoend-color.jpg' filename='endtoend-color.jpeg'>";
      document.getElementById('loaderStart').style.display = 'none';
      document.getElementById("blockCapture").appendChild(divLink);
    });
  }

  // Exportation de l'image lorsque l'événement "click" est déclenché sur l'élément exportJPG
  exportJPG.addEventListener("click", () => {
    // Vérification de la présence de couleurs à exporter
    const colorAdd = document.querySelectorAll("#print input.quantite").length;
    if (colorAdd > 0) {
    // Affichage du chargement de l'image
     //Selection du loader et affichage de celui ci
  document.getElementById('loaderStart').style.display = 'grid';
    // Exécution de la fonction d'exportation avec un délai de 1 seconde
    setTimeout(() => {
    exportCanvas();
    }, 1000);
    } else {
    // Notification indiquant qu'il n'y a pas de couleurs à exporter
    const notifExportJpg = new Notifications(
    "Aucune couleur à exporter",
    "#d80028"
    );
    }
    });
    
    // Sélection de l'élément de fermeture de la fenêtre de capture
    const closeCapture = document.querySelector("#closeCapture");
    // Fermeture de la fenêtre de capture lorsque l'événement "click" est déclenché sur l'élément closeCapture
    closeCapture.addEventListener("click", () => {
    // Masquage de la fenêtre de capture
    document.querySelector("#capture").style.display = "none";
    // Masquage du chargement de l'image
     //Selection du loader et affichage de celui ci
    document.getElementById('loaderStart').style.display = 'none';
    // Affichage des boutons de déplacement et de suppression des couleurs
    document.querySelectorAll("#print .btnDrag").forEach((btn) => {
    btn.style.display = "block";
    });
    document.querySelectorAll("#print .btnSupprimer").forEach((btn) => {
    btn.style.display = "block";
    });
    });
    }

     

    export { exportJpg };