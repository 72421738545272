
import { Notifications } from './notifs.js';
import { panier } from './panier.js';


/**
 * Fonction qui permet de passer à l'input suivant
 * 
 * @param {*} nextInput 
 * 
 */
function enterInput(nextInput) {
    nextInput.forEach(input => {
        input.addEventListener('keyup', function (e) {
            if (e.keyCode === 13) {
                let inputs = nextInput
                let index = Array.prototype.indexOf.call(inputs, e.target);
                if (index < inputs.length - 1) {
                    inputs[index + 1].focus();
                }
            }
        });
    });
}


/**
* Fonction qui permet de supprimer un item
* 
* @param {*} supps 
*/
function suppItem(supps) {
    supps.forEach(supp => {
        supp.addEventListener('click', () => {
            supp.parentNode.remove();
        })
    })
}


/** 
 * supprimer tout les items et remise à zéro du panier
 * @param {*} zone 
 */
function suppAllItems(zone) {
    const btnSuppAll = document.querySelector('#SuppAll');
    btnSuppAll.addEventListener('click', () => {
        const colorAdd = document.querySelectorAll("#print input.quantite").length;
        if (colorAdd === 0) {
            const notif = new Notifications("Aucune selection à supprimer", "#d80028");
            return;
        }

        const confirmation = window.confirm(`Supprimer la selection de couleur ?
    N'oubliez pas de la sauvegarder avant !`);
        if (!confirmation) {
            console.log('suppression annulé');
            return;
        }

        zone.innerHTML = '';
        panier(0);
        console.log('Ensemble supprimé');
        const notifSupp = document.querySelector('.notification');
        notifSupp.innerHTML = "Selection supprimée";
        notifSupp.style.display = 'block';
        notifSupp.style.backgroundColor = '#d80028';
        setTimeout(() => { notifSupp.style.display = 'none'; }, 3000);
    });
}


/**
 * Fonction qui d'afficher l'aide 
 */
function helpToggle() {
    const help = document.querySelector('#help');
    const helpModal = document.querySelector('.helper');
    const closeHelp = document.querySelector('#closeHelp');

    const toggleHelpModal = () => {
        helpModal.style.display = helpModal.style.display === 'block' ? 'none' : 'block';
    }

    help.addEventListener('click', toggleHelpModal);
    closeHelp.addEventListener('click', toggleHelpModal);
}



/**
 * Fonction qui permet de cloner un item
 * 
 * @param {*} clones 
 * @param {*} destination 
 */


function cloneColors(clones, destination) {
    clones.forEach(clone => {
      clone.addEventListener('click', () => {
        const cloneItem = clone.parentNode.cloneNode(true);
        destination.prepend(cloneItem);
        console.log('item cloned');
        const notification = new Notifications('Couleur ajoutée à la selection', '#6bb66c')

      })
    })
  }



/**
 * Animation closeProposition
 * @param {*} fadeTarget 
 * @param {*} duration 
 * @param {*} endOpacity 
 */
  /*function fadeOutEffect(fadeTarget, duration = 2, endOpacity = 0) {
   let opacity = 1;
    let height = fadeTarget.offsetHeight; 
    
    const shrinkInterval = setInterval(() => {
      fadeTarget.style.height = `${height}px`;
      height -= 1;
      if (height <= 0) {
        clearInterval(shrinkInterval);
      }
    }, duration);

    const fadeInterval = setInterval(() => {
      fadeTarget.style.opacity = opacity;
      opacity -= 0.1;
      if (opacity <= endOpacity) {
        clearInterval(fadeInterval);
        fadeTarget.style.display = 'none';
      }
    }, duration);
  
   
  }*/
  
  /**
   * Fermeture des propositions 
   * 
   * @param {*} sections 
   * @param {*} buttons 
   */
  function closePropositions(sections, buttons) {
    buttons.forEach(button => {
      button.addEventListener('click', () => {
        sections.forEach(section => {
          section.classList.add('animate__fadeInOut');
          section.style.display = 'none';

         // fadeOutEffect(section);
        });
      });
    });
  }


  
export { enterInput, suppItem, suppAllItems, helpToggle, cloneColors, closePropositions};


