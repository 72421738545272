export default[
  {
    "hex": "#fdf4a6",
    "name": "jasmin Yellow",
    "ref": "1",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#ffe655",
    "name": "zinc Yellow",
    "ref": "2",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#ffd900",
    "name": "cadmium Yellow",
    "ref": "3",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#ffcc00",
    "name": "signal Yellow",
    "ref": "4",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#faf4e3",
    "name": "nature White",
    "ref": "5",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#fff0b9",
    "name": "vanilla",
    "ref": "6",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#ffe26e",
    "name": "cashmere Yellow",
    "ref": "7",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#ffd059",
    "name": "quince",
    "ref": "8",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#fbbc00",
    "name": "golden Yellow",
    "ref": "9",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#f7ad00",
    "name": "melon Yellow",
    "ref": "10",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#f18d00",
    "name": "light Orange",
    "ref": "11",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#ed7920",
    "name": "pastel Orange",
    "ref": "12",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#ed6916",
    "name": "DARE Orange Light",
    "ref": "13",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#e15c0f",
    "name": "DARE Orange",
    "ref": "14",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#df4f1a",
    "name": "LINDA Sunset",
    "ref": "15",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#d4021e",
    "name": "SWEET Traffic Red",
    "ref": "16",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#c2002d",
    "name": "tornado Red",
    "ref": "17",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#990c26",
    "name": "ruby Red",
    "ref": "18",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#71181f",
    "name": "burgundy",
    "ref": "19",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#4d171c",
    "name": "garnet",
    "ref": "20",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#3e1715",
    "name": "black Red",
    "ref": "21",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#3a2119",
    "name": "LOOMIT Aubergine",
    "ref": "22",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#facdb0",
    "name": "peach Pastel",
    "ref": "23",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#f8c1a0",
    "name": "peach Light",
    "ref": "24",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#f6b288",
    "name": "peach",
    "ref": "25",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#f08d4a",
    "name": "goldfish",
    "ref": "26",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#ed7729",
    "name": "lobster Light",
    "ref": "27",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#e95f24",
    "name": "lobster Mrefdle",
    "ref": "28",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#e7502a",
    "name": "lobster",
    "ref": "29",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#be362a",
    "name": "vermillion",
    "ref": "30",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#db4430",
    "name": "shock Orange",
    "ref": "31",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#d80028",
    "name": "MADC Cherry Red",
    "ref": "32",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#c00417",
    "name": "signalRed",
    "ref": "33",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#f39a5a",
    "name": "apricot",
    "ref": "34",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#db633a",
    "name": "salmon Orange",
    "ref": "35",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#a53513",
    "name": "autumn Leaf",
    "ref": "36",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#f4beae",
    "name": "LOOMIT Apricot Pastel",
    "ref": "37",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#f3a28b",
    "name": "LOOMIT Apricot Light",
    "ref": "38",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#ee826f",
    "name": "LOOMIT Apricot Mrefdle",
    "ref": "39",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#eb6a4a",
    "name": "LOOMIT Apricot",
    "ref": "40",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#d45646",
    "name": "strawberry Red",
    "ref": "41",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#be4538",
    "name": "pussy Pink",
    "ref": "42",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#bb2037",
    "name": "raspberry Red",
    "ref": "43",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#9c052d",
    "name": "sangria",
    "ref": "44",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#f8cac2",
    "name": "grapefruit Light",
    "ref": "45",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#f5b2a7",
    "name": "grapefruit",
    "ref": "46",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#e2a09a",
    "name": "mauve Light",
    "ref": "47",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#cf908c",
    "name": "mauve",
    "ref": "48",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#a9736a",
    "name": "antique Pink Mrefdle",
    "ref": "49",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#996a63",
    "name": "antique Pink",
    "ref": "50",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#f7c6cd",
    "name": "piglet  Pink Light",
    "ref": "51",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#f0a2b9",
    "name": "piglet Pink",
    "ref": "52",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#dc7f9f",
    "name": "candy Pink",
    "ref": "53",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#b74d74",
    "name": "lipstick",
    "ref": "54",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#74324a",
    "name": "purple",
    "ref": "55",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#6c3640",
    "name": "ESHER Dirty Red",
    "ref": "56",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#de95bc",
    "name": "TILT Bubble Pink",
    "ref": "57",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#d36aa2",
    "name": "fuchsia Pink",
    "ref": "58",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#d94476",
    "name": "MADC Psycho Pink",
    "ref": "59",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#b71d5a",
    "name": "telemagenta",
    "ref": "60",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#910041",
    "name": "amaranth Red",
    "ref": "61",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#68184b",
    "name": "MACrew Purple",
    "ref": "62",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#3e1f30",
    "name": "purple Violet",
    "ref": "63",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#d7c7db",
    "name": "crocus",
    "ref": "64",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#bca0c0",
    "name": "lavender",
    "ref": "65",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#a77ea9",
    "name": "lilac",
    "ref": "66",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#856195",
    "name": "grape",
    "ref": "67",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#673c7c",
    "name": "blackberry",
    "ref": "68",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#4c2561",
    "name": "currant",
    "ref": "69",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#251241",
    "name": "Crazy Prune",
    "ref": "70",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#33265e",
    "name": "dark Purple",
    "ref": "71",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#21194b",
    "name": "black Violet",
    "ref": "72",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#d9dad6",
    "name": "thistle",
    "ref": "73",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#c7c4cf",
    "name": "viola Pastel",
    "ref": "74",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#a3a0c3",
    "name": "viola Light",
    "ref": "75",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#7b7cab",
    "name": "viola Mrefdle",
    "ref": "76",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#6167a2",
    "name": "viola",
    "ref": "77",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#464e96",
    "name": "viola Dark",
    "ref": "78",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#243b81",
    "name": "night SEEN Blue",
    "ref": "79",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#505c8e",
    "name": "grape Mrefdle",
    "ref": "80",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#3a4187",
    "name": "grape Dark",
    "ref": "81",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#232a60",
    "name": "purple Velvet",
    "ref": "82",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#7d83b4",
    "name": "light Violet",
    "ref": "83",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#595c79",
    "name": "plum Mrefdle",
    "ref": "84",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#383959",
    "name": "plum",
    "ref": "85",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#a6b3cc",
    "name": "pigeon Blue Mrefdle",
    "ref": "86",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#8d9fc0",
    "name": "pigeon Blue",
    "ref": "87",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#6581b1",
    "name": "blueberry Light",
    "ref": "88",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#d1e6ee",
    "name": "azure Blue Light",
    "ref": "89",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#b9dcec",
    "name": "azure Blue",
    "ref": "90",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#a1cee4",
    "name": "shock Blue Pastel",
    "ref": "91",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#88bcd8",
    "name": "shock Blue Light",
    "ref": "92",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#59a8cd",
    "name": "shock Blue Mrefdle",
    "ref": "93",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#1788bc",
    "name": "shock Blue",
    "ref": "94",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#3683bd",
    "name": "tulip Blue Light",
    "ref": "95",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#0067a3",
    "name": "tulip Blue Mrefdle",
    "ref": "96",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#004a83",
    "name": "tulip Blue",
    "ref": "97",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#3395b9",
    "name": "light Blue",
    "ref": "98",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#77a4c7",
    "name": "ceramic Blue Pastel",
    "ref": "99",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#5088ae",
    "name": "jeans Blue",
    "ref": "100",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#315484",
    "name": "wild WANE Blue",
    "ref": "101",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#1f3d79",
    "name": "navy Blue",
    "ref": "102",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#002c5c",
    "name": "navy ultra Blue",
    "ref": "103",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#002e59",
    "name": "sapphire Blue",
    "ref": "104",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#002743",
    "name": "indigo",
    "ref": "105",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#00243b",
    "name": "Prussian Blue dark",
    "ref": "106",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#acd0de",
    "name": "deep sea Blue Mrefdle",
    "ref": "106-1",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#6bb8d6",
    "name": "deep sea Blue",
    "ref": "106-2",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#7c9bab",
    "name": "deep sea Blue dark",
    "ref": "106-3",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#0f3d50",
    "name": "petrol",
    "ref": "107",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#1b394a",
    "name": "seabed Blue",
    "ref": "108",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#00233c",
    "name": "seabed Blue dark",
    "ref": "109",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#97ccd8",
    "name": "ice Blue Dark",
    "ref": "110",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#468f9c",
    "name": "dolphin Blue",
    "ref": "111",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#006a7d",
    "name": "petrol Blue",
    "ref": "112",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#6bb8c4",
    "name": "cream Blue",
    "ref": "113",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#489aa1",
    "name": "olympia Blue",
    "ref": "114",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#217f8b",
    "name": "aqua",
    "ref": "115",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#005362",
    "name": "alga",
    "ref": "116",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#b6dcd2",
    "name": "baby Blue",
    "ref": "117",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#88cac1",
    "name": "crystal Blue Dark",
    "ref": "118",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#54a79c",
    "name": "blue Gin",
    "ref": "119",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#508389",
    "name": "verdigris",
    "ref": "120",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#d0e8dd",
    "name": "caribbean",
    "ref": "121",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#a8d5c4",
    "name": "riviera Pastel",
    "ref": "122",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#80c2ae",
    "name": "riviera Light",
    "ref": "123",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#44b39b",
    "name": "riviera Mrefdle",
    "ref": "124",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#00998a",
    "name": "riviera Dark",
    "ref": "125",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#00857c",
    "name": "lagoon Blue",
    "ref": "126",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#00605b",
    "name": "martha Blue",
    "ref": "127",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#00454d",
    "name": "turquoise Dark",
    "ref": "128",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#003c43",
    "name": "nori",
    "ref": "129",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#00333d",
    "name": "black turquoise",
    "ref": "130",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#aab98f",
    "name": "gale Green",
    "ref": "131",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#8fb18d",
    "name": "reed green",
    "ref": "132",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#77a479",
    "name": "aquamarine",
    "ref": "133",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#4d7057",
    "name": "swamp",
    "ref": "134",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#3d5b46",
    "name": "black Forest Green",
    "ref": "135",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#d3e3d7",
    "name": "blueberry Pastel",
    "ref": "136",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#c2deba",
    "name": "calypso Light",
    "ref": "137",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#74bd8b",
    "name": "calypso Mrefdle",
    "ref": "138",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#53a977",
    "name": "calypso Dark",
    "ref": "139",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#00794d",
    "name": "turquoise Green",
    "ref": "140",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#005a3c",
    "name": "turquoise Green Mrefdle",
    "ref": "141",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#004932",
    "name": "turquoise Green Dark",
    "ref": "142",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#004230",
    "name": "SEAK Furure green",
    "ref": "143",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#b8d69d",
    "name": "menthol Light",
    "ref": "144",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#6bb66c",
    "name": "menthol",
    "ref": "145",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#009952",
    "name": "KACAO 77 green",
    "ref": "146",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#00873f",
    "name": "lettuce Green",
    "ref": "147",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#dfe38c",
    "name": "kiwi Pastel",
    "ref": "148",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#bbce3c",
    "name": "kiwi Light",
    "ref": "149",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#96b922",
    "name": "kiwi",
    "ref": "150",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#c8d97d",
    "name": "dandelion",
    "ref": "151",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#b2cc54",
    "name": "brilliant green",
    "ref": "152",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#88bb3c",
    "name": "grasshopper",
    "ref": "153",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#a6c85c",
    "name": "cream green",
    "ref": "154",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#89c25a",
    "name": "hippie green",
    "ref": "155",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#7fb94f",
    "name": "wasabi",
    "ref": "156",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#57ab27",
    "name": "cliff Green",
    "ref": "157",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#2da43f",
    "name": "clover Green",
    "ref": "158",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#008834",
    "name": "juice green",
    "ref": "159",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#00763b",
    "name": "Mister Green",
    "ref": "160",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#157727",
    "name": "leaf Green mrefdle",
    "ref": "160-1",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#1f5a2e",
    "name": "leaf Green",
    "ref": "161",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#94bf3d",
    "name": "Mighty Green",
    "ref": "162",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#6a9619",
    "name": "avocado",
    "ref": "163",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#567513",
    "name": "fern Green",
    "ref": "164",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#325815",
    "name": "moss Green",
    "ref": "165",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#2c4025",
    "name": "chromium Oxrefe Green",
    "ref": "166",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#002c1c",
    "name": "black Green",
    "ref": "167",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#dae07f",
    "name": "butterfly Green",
    "ref": "168",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#bac579",
    "name": "green Tea",
    "ref": "169",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#949955",
    "name": "P.JAY Green",
    "ref": "170",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#908f5e",
    "name": "amazonas",
    "ref": "171",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#5e5d33",
    "name": "camouflage",
    "ref": "172",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#414423",
    "name": "evil Olive",
    "ref": "173",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#dede2e",
    "name": "poison Green",
    "ref": "174",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#ccc545",
    "name": "apple",
    "ref": "175",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#efe397",
    "name": "sorbet Green",
    "ref": "176",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#e5e18c",
    "name": "soft Pastel",
    "ref": "177",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#c1c13b",
    "name": "carambola",
    "ref": "178",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#b4a338",
    "name": "hemp",
    "ref": "179",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#98961e",
    "name": "green Bean",
    "ref": "180",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#828423",
    "name": "pear",
    "ref": "181",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#606e24",
    "name": "green natural light",
    "ref": "181-1",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#5e5e20",
    "name": "green natural Medium",
    "ref": "181-2",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#d3ba58",
    "name": "mustard",
    "ref": "182",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#d79a00",
    "name": "yellow mustard",
    "ref": "182-1",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#a37622",
    "name": "khaki green",
    "ref": "182-1",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#ac8c34",
    "name": "khaki",
    "ref": "183",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#fdeac5",
    "name": "ivory Light",
    "ref": "184",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#faf2df",
    "name": "sand Light",
    "ref": "184-1",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#f1dbcb",
    "name": "sand medium",
    "ref": "184-2",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#d9b092",
    "name": "sand",
    "ref": "184-3",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#d5a78b",
    "name": "sand quartz",
    "ref": "184-4",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#ebcebe",
    "name": "sand quartz medium",
    "ref": "184-5",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#e9cfc6",
    "name": "sand quartz light",
    "ref": "184-6",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#e4d7d0",
    "name": "white fur",
    "ref": "184-7",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#d5b172",
    "name": "milk Coffee",
    "ref": "185",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#a47a4e",
    "name": "Cappuccino",
    "ref": "186",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#86653d",
    "name": "Espresso",
    "ref": "187",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#533e27",
    "name": "Mocca",
    "ref": "188",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#f6deb2",
    "name": "sahara Beige Light",
    "ref": "189",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#f1cb83",
    "name": "sahara Beige Medium",
    "ref": "190",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#ecd898",
    "name": "sahara Beige",
    "ref": "191",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#c2964a",
    "name": "papyrus",
    "ref": "192",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#b87b3f",
    "name": "nougat",
    "ref": "193",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#834e2d",
    "name": "beige brown",
    "ref": "194",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#facf9b",
    "name": "apricot Beige",
    "ref": "195",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#edbf8b",
    "name": "labrador",
    "ref": "196",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#dc9b55",
    "name": "camel",
    "ref": "197",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#ac6f2e",
    "name": "ocher Brown",
    "ref": "198",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#f49e4e",
    "name": "orange Brown Light",
    "ref": "199",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#d7722e",
    "name": "orange Brown Medium",
    "ref": "200",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#b4591d",
    "name": "orange Brown",
    "ref": "201",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#9f5348",
    "name": "cocoa Light",
    "ref": "202",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#9c6169",
    "name": "cocoa Medium",
    "ref": "203",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#783a2f",
    "name": "cocoa",
    "ref": "204",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#d7a795",
    "name": "caramel Light",
    "ref": "204-1",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#a76745",
    "name": "caramel",
    "ref": "205",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#75462a",
    "name": "walnut",
    "ref": "206",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#5e3121",
    "name": "hazelnut",
    "ref": "207",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#4b301e",
    "name": "chocolate Brown",
    "ref": "208",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#3f271b",
    "name": "CMP dark brown",
    "ref": "209",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#3c3b36",
    "name": "black tar",
    "ref": "210",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#5d5947",
    "name": "stone grey dark",
    "ref": "211",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#848169",
    "name": "stone grey medium",
    "ref": "212",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#c4c1ab",
    "name": "stone grey light",
    "ref": "213",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#061722",
    "name": "TOAST Signal black",
    "ref": "214",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#41403e",
    "name": "black grey neutral",
    "ref": "215",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#5f5f5f",
    "name": "dark grey neutral",
    "ref": "216",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#989898",
    "name": "middle grey neutral",
    "ref": "216",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#d8d7cc",
    "name": "light grey neutral",
    "ref": "218",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#707574",
    "name": "mouse grey",
    "ref": "219",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#F0E68C",
    "name": "Gold dollar",
    "ref": "220-1",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#C0C0C0",
    "name": "silver dollar",
    "ref": "220",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#000000",
    "name": "black grey dark",
    "ref": "221",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#5d5d5b",
    "name": "black grey light",
    "ref": "221-1",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#505050",
    "name": "black grey medium",
    "ref": "221-2",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#303330",
    "name": "black grey dark",
    "ref": "222",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#2c3134",
    "name": "anthracite grey dark",
    "ref": "222-1",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#3c4144",
    "name": "anthracite grey",
    "ref": "223",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#4e5254",
    "name": "anthracite grey medium",
    "ref": "223-1",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#6e7172",
    "name": "grey blue dark",
    "ref": "224",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#848788",
    "name": "granite",
    "ref": "225",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#9c9e9e",
    "name": "grey blue medium",
    "ref": "226",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#adaeac",
    "name": "rock grey",
    "ref": "227",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#bdbcbb",
    "name": "grey blue light",
    "ref": "228",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#cdcbca",
    "name": "pebble grey",
    "ref": "229",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#dad8d8",
    "name": "Marble grey",
    "ref": "230",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#ffffff",
    "name": "signal white",
    "ref": "231",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#f3f315",
    "name": "Jaune Néon",
    "ref": "232",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#ff6600",
    "name": "Orange Néon",
    "ref": "233",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#ff0099",
    "name": "Rose Néon",
    "ref": "234",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#c24673",
    "name": "Violet Néon",
    "ref": "234-1",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#01b0f0",
    "name": "Bleu Néon",
    "ref": "235",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#35c096",
    "name": "Turquoise  Néon",
    "ref": "235-1",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#83f52c",
    "name": "Vert Néon",
    "ref": "236",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  },
  {
    "hex": "#ff0000",
    "name": "ANTISTATIK Rouge Néon",
    "ref": "236-1",
    "marque": "molotow",
    "nuancier": "colorsMolotow"
  }
]