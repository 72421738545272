export default [
  {
    "hex": "#FFE885",
    "name": "Cadmium Yellow Light",
    "ref": "RV-222",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#FFDD35",
    "name": "Cadmium Yellow Medium",
    "ref": "RV-1021",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#EAB63E",
    "name": "Azo Yellow Deep",
    "ref": "RV-177",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#BA8032",
    "name": "Yellow Ocre",
    "ref": "RV-264",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#9D6F3C",
    "name": "Raw Sienna",
    "ref": "RV-265",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#F7B792",
    "name": "Azo Orange Pale",
    "ref": "RV-102",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#FF9955",
    "name": "Azo Orange Light",
    "ref": "RV-105",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#FF6F22",
    "name": "Azo Orange",
    "ref": "RV-2004",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#F05136",
    "name": "Azo Orange Deep",
    "ref": "RV-209",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#e84921",
    "name": "Azo Orange Dark",
    "ref": "RV-347",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#F39C9A",
    "name": "Cadmium Red Pale",
    "ref": "RV-322",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#EA746A",
    "name": "Cadmium Red Light",
    "ref": "RV-223",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#d60530",
    "name": "Naphthol Red",
    "ref": "RV-3020",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#BE0D00",
    "name": "Naphthol Red Deep",
    "ref": "RV-241",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#901427",
    "name": "Carmine",
    "ref": "RV-3004",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#F5AFC5",
    "name": "Quinacridone Rose Light",
    "ref": "RV-323",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#ee78a8",
    "name": "Quinacridone Rose",
    "ref": "RV-211",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#dd2365",
    "name": "Quinacridone Magenta",
    "ref": "RV-4010",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#951247",
    "name": "Red Violet",
    "ref": "RV-213",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#833A55",
    "name": "Red Violet Deep",
    "ref": "RV-324",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#D9A5BE",
    "name": "Blue Violet Pale",
    "ref": "RV-325",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#d071aa",
    "name": "Blue Violet Light",
    "ref": "RV-224",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#bd558e",
    "name": "Blue Violet",
    "ref": "RV-225",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#6b0037",
    "name": "Blue Violet Deep",
    "ref": "RV-167",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#57374C",
    "name": "Blue Violet Dark",
    "ref": "RV-169",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#CFBFDE",
    "name": "Dioxazine Purple Pale",
    "ref": "RV-321",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#a194c5",
    "name": "Dioxazine Purple Light",
    "ref": "RV-214",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#6b4d8d",
    "name": "Dioxazine Purple",
    "ref": "RV-173",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#483366",
    "name": "Dioxazine Purple Deep",
    "ref": "RV-174",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#27234F",
    "name": "Dioxazine Purple Dark",
    "ref": "RV-28",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#8AD8ED",
    "name": "Phthalo Blue Pale",
    "ref": "RV-326",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#74c8ea",
    "name": "Phthalo Blue Light",
    "ref": "RV-29",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#01abd8",
    "name": "Cerulean Blue",
    "ref": "RV-217",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#006396",
    "name": "Prussian Blue",
    "ref": "RV-30",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#005082",
    "name": "Prussian Blue Deep",
    "ref": "RV-154",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#88C5E4",
    "name": "Cobalt Blue Pale",
    "ref": "RV-327",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#4590c8",
    "name": "Cobalt Blue Light",
    "ref": "RV-69",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#0182cc",
    "name": "Cobalt Blue",
    "ref": "RV-68",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#003774",
    "name": "Ultramarine Blue",
    "ref": "RV-5002",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#003774",
    "name": "Ultramarine Blue Deep",
    "ref": "RV-328",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#5CA5DA",
    "name": "Primary Blue Pale",
    "ref": "RV-336",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#5388C6",
    "name": "Primary Blue Light",
    "ref": "RV-337",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#245BA7",
    "name": "Primary Blue",
    "ref": "RV-338",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#23388D",
    "name": "Primary Blue Deep",
    "ref": "RV-339",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#25348A",
    "name": "Primary Blue Dark",
    "ref": "RV-340",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#88CDD3",
    "name": "Blue Green Pale",
    "ref": "RV-341",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#06B5D2",
    "name": "Blue Green Light",
    "ref": "RV-342",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#028DB4",
    "name": "Blue Green",
    "ref": "RV-343",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#00789A",
    "name": "Blue Green Deep",
    "ref": "RV-14",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#005476",
    "name": "Blue Green Dark",
    "ref": "RV-234",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#a1d3be",
    "name": "Phathalo Green Blue",
    "ref": "RV-254",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#44b8af",
    "name": "Turquoise Green",
    "ref": "RV-219",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#57B798",
    "name": "Emerald Green Light",
    "ref": "RV-220",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#009482",
    "name": "Emerald Green",
    "ref": "RV-21",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#055F5D",
    "name": "Emeral Green Deep",
    "ref": "RV-221",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#E6E782",
    "name": "Brillant Yellow Green Light",
    "ref": "RV-235",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#b9ce12",
    "name": "Brillant Yellow Green",
    "ref": "RV-236",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#9BBE53",
    "name": "Brillant Yellow Green Medium",
    "ref": "RV-333",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#569426",
    "name": "Brillant Yellow Green Deep",
    "ref": "RV-334",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#36792B",
    "name": "Brillant Yellow Green Dark",
    "ref": "RV-335",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#C8DD9B",
    "name": "Phathalo Green Light",
    "ref": "RV-124",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#9CCC95",
    "name": "Phathalo Green",
    "ref": "RV-329",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#6eb748",
    "name": "Brillant Light Green",
    "ref": "RV-34",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#56944F",
    "name": "Brillant Green",
    "ref": "RV-6018",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#255C1E",
    "name": "Brillant Green Deep",
    "ref": "RV-127",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#C4CBA9",
    "name": "Grey Green Pale",
    "ref": "RV-344",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#BEC39E",
    "name": "Grey Green Light",
    "ref": "RV-345",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#757A4F",
    "name": "Grey Green",
    "ref": "RV-179",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#647253",
    "name": "Grey Green Deep",
    "ref": "RV-131",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#4A5D45",
    "name": "Grey Green Dark",
    "ref": "RV-346",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#f3efd6",
    "name": "Titanium light",
    "ref": "RV-1013",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#eadfa8",
    "name": "Naples Yellow",
    "ref": "RV-135",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#D7B483",
    "name": "Naples Yellow Deep",
    "ref": "RV-330",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#B0946F",
    "name": "Raw Umber",
    "ref": "RV-137",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#745B48",
    "name": "Raw Umber Deep",
    "ref": "RV-139",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#D9D6CF",
    "name": "Warm Grey Pale",
    "ref": "RV-331",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#CbC2B0",
    "name": "Warm Grey Light",
    "ref": "RV-302",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#C0B6A4",
    "name": "Warm Grey Medium",
    "ref": "RV-303",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#645C59",
    "name": "Warm Grey Deep",
    "ref": "RV-332",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#66564F",
    "name": "Burnt Umber",
    "ref": "RV-266",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#D2D7D8",
    "name": "Neutral Grey Pale",
    "ref": "RV-6",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#B4B8B6",
    "name": "Neutral Grey Light",
    "ref": "RV-7047",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#A5AAA8",
    "name": "Neutral Grey",
    "ref": "RV-7040",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#676C73",
    "name": "Neutral Grey Deep",
    "ref": "RV-263",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#555357",
    "name": "Neutral Grey Dark",
    "ref": "RV-7016",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#FFED39",
    "name": "Fluorescent Yellow",
    "ref": "RV-Fluo-1",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#F95951",
    "name": "Fluorescent Red",
    "ref": "RV-Fluo-2",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#FF0093",
    "name": "Fluorescent Magenta",
    "ref": "RV-Fluo-2",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#60DD49",
    "name": "Fluorescent Green",
    "ref": "RV-Fluo-3",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#00AACC",
    "name": "Fluorescent Blue",
    "ref": "RV-Fluo-4",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#000000",
    "name": "Carbon Black",
    "ref": "RV-Carbon",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#FFFFFF",
    "name": "Titanium White",
    "ref": "RV-Titanium",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#999999",
    "name": "Silver",
    "ref": "RV-Silver",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#826107",
    "name": "Gold",
    "ref": "RV-Gold",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#000000",
    "name": "Transparent Black",
    "ref": "RV-T-Black",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#FFFFFF",
    "name": "Transparent White",
    "ref": "RV-T-White",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#f1f1f1",
    "name": "Glossy Varnish",
    "ref": "RV-Glossy",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  },
  {
    "hex": "#f3f3f3",
    "name": "Matt Varnish",
    "ref": "RV-Matt",
    "marque": "montana water based",
    "nuancier": "montanaBased"
  }
]