export default [
    {
        "hex": "#F4E593",
        "ref": "BLK 1005"
    },
    {
        "hex": "#F49F24",
        "ref": "BLK 2060"
    },
    {
        "hex": "#E7478A",
        "ref": "BLK 3100"
    },
    {
        "hex": "#EE8363",
        "ref": "BLK 3200"
    },
    {
        "hex": "#A8438F",
        "ref": "BLK 3940"
    },
    {
        "hex": "#6F89BC",
        "ref": "BLK 4310"
    },
    {
        "hex": "#00A2D3",
        "ref": "BLK 5020"
    },
    {
        "hex": "#52676C",
        "ref": "BLK 5125"
    },
    {
        "hex": "#C9D215",
        "ref": "BLK 6000"
    },
    {
        "hex": "#51AB27",
        "ref": "BLK 6045"
    },
    {
        "hex": "#4AADC4",
        "ref": "BLK 6110"
    },
    {
        "hex": "#A3AC7C",
        "ref": "BLK 6410"
    },
    {
        "hex": "#C7C8B8",
        "ref": "BLK 7010"
    },
    {
        "hex": "#A69FA2",
        "ref": "BLK 7210"
    },
    {
        "hex": "#CB916C",
        "ref": "BLK 8000"
    },
    {
        "hex": "#030506",
        "ref": "BLK 9001"
    },
    {
        "hex": "#E51175",
        "ref": "BLK TR 5000"
    }

]