import { Notifications } from './notifs.js';
import html2canvas from 'html2canvas';



async function shareImage(zone, shareButton, canvasShare, printElements) {
  // Cache les éléments d'impression et affiche le loader
  printElements.forEach(el => el.style.display = 'none');

  // Transforme la zone en un canvas
  const canvas = await html2canvas(zone, { width: zone.scrollWidth, height: zone.scrollHeight });

  // Convertit le canvas en une URL de l'image au format JPEG
  const canvasData = canvas.toDataURL('image/jpeg', 1.0);

  // Ajoute l'image au canvas de partage
  canvasShare.innerHTML = `<img class='shareImg' src='${canvasData}' title='endtoend-color.jpg' filename='endtoend-color.jpg'>`;

  // Convertit l'image en un fichier blob
  const blob = await (await fetch(canvasData)).blob();
  const file = new File([blob], 'fileName.jpeg', { type: blob.type });

  // Partage le fichier avec un titre et un texte prédéfinis
  await navigator.share({
    title: 'Nuancier - color.endtoend.fr',
    text: 'Nuancier - color.endtoend.fr',
    files: [file],
  });

  // Affiche à nouveau les éléments d'impression et cache le loader
  printElements.forEach(el => el.style.display = 'block');
  loaderJpg.style.display = 'none';
}

function socialShareImg(zone) {
  // Récupère le bouton de partage et les éléments du canvas et du loader
  const shareButton = document.querySelector('#partager');
  const canvasShare = document.getElementById("canvasShare");

  // Récupère tous les éléments d'impression
  const printElements = document.querySelectorAll('#print .btnMove, #print .btnSupp');

  // Vérifie si la fonction de partage est disponible sur le navigateur
  if (!navigator.share) {
    // Si ce n'est pas le cas, cache le bouton de partage
    document.querySelector('#partager').style.display = 'none';
  }

  // Ajoute un écouteur d'événement au bouton de partage qui exécute la fonction "shareImage" lorsque l'utilisateur clique dessus
  shareButton.addEventListener('click', () => {
    // Vérifie s'il y a au moins une couleur ajoutée à l'écran d'impression
    const colorAdd = document.querySelectorAll("#print input.quantite").length;
    if (colorAdd > 0) {
      document.getElementById('loaderStart').style.display = 'grid';
      // Affiche une notification indiquant que l'image est en train de charger
      const notif = new Notifications("Préparation au partage", "#59a8cd");
      // Si oui, exécute la fonction "shareImage"    

      shareImage(zone, shareButton, canvasShare, printElements);
      //document.getElementById('loaderStart').style.display = 'none';
      
      setTimeout(function () {
        document.getElementById('loaderStart').style.display = 'none';
      }, 3000); // Le contenu est chargé au bout de 2 seconde 


    } else {
      const notif = new Notifications('Veuillez selection des couleurs à partager', 'rgb(216, 0, 40)')
    }
  });
}




export { socialShareImg };