
/**
 * 
 * @param {*} elementToShow 
 * @param {*} elementToHide 
 */
function toggleDisplay(elementToShow, elementToHide) {
    elementToShow.style.display = 'flex';
    elementToHide.style.display = 'none';
  }
  
  /**
   * 
   * @param {*} btn 
   * @param {*} couleur 
   * @param {*} nuancier 
   */
  function displayNuancier(btn, couleur, nuancier) {
    btn.addEventListener('click', () => {
      if (nuancier.style.display === 'none') {
        toggleDisplay(nuancier, couleur);
        btn.classList.add('active');
      } else {
        toggleDisplay(couleur, nuancier);
        btn.classList.remove('active');
      }
    });
  }
  
  /**
   * 
   * @param {*} valides 
   * @param {*} nuancier 
   * @param {*} couleur 
   * @param {*} btn 
   */
  function hideNuancier(valides, nuancier, couleur, btn) {
    valides.forEach(valide => {
      valide.addEventListener('click', () => {
        toggleDisplay(couleur, nuancier);
        btn.classList.remove('active');
      });
    });
  }


  function appNuanciers(){
    const btnNuancier = document.querySelector('.selectNuancier');
    const couleurListe = document.querySelector('.couleurListe');
    const nuancierListe = document.querySelector('.nuancierListe');
    const valideNuancier = document.querySelectorAll('.nuancierListe a');
    
    displayNuancier(btnNuancier, couleurListe, nuancierListe);
    hideNuancier(valideNuancier, nuancierListe, couleurListe, btnNuancier);
    
    const btnNuancierMobile = document.querySelector('.selectNuancier-m');
    const couleurListeMobile = document.querySelector('.color-pick-m .couleurListe');
    const nuancierListeMobile = document.querySelector('.color-pick-m .nuancierListe');
    const valideNuancierMobile = document.querySelectorAll('.color-pick-m .nuancierListe a');
    
    nuancierListeMobile.style.display = 'none';
    displayNuancier(btnNuancierMobile, couleurListeMobile, nuancierListeMobile);
    hideNuancier(valideNuancierMobile, nuancierListeMobile, couleurListeMobile, btnNuancierMobile);
    }
    

  export {appNuanciers};