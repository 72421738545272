import { suppItem, enterInput } from "./fonctions.js";


/*function recupererPrix(inputElement) {
    // Add the event listener to the input element
    inputElement.addEventListener('input', handleInputChange);
  }*/
  
  // Define a separate function to handle the input change event
  function handleInputChange(event) {
    // Get the input value from the event
    const inputValue = event.target.value;
    console.log('prix :', inputValue);
  
    // Use the input value to update the total cost display
    displayTotalItems(inputValue);
  }
  
  /**
   * Affichage conditionnel du panier
   * 
   * @param {*} totalSum int !
   * 
   */
  
  function panier(totalSum) {
    if (isNaN(totalSum)) {
      displayErrorMessage('Entrez des quantités pour calculer le devis');
    } else {
      displayTotalItems(totalSum);
    }
  }
  
  function displayErrorMessage(message) {
    document.getElementById('res-name').innerHTML = message;
    document.getElementById('res-name-m').innerHTML = message;
  }
  
  function displayTotalItems(totalItem) {
    const totalCost = totalItem * 4.50; // *4.50
    const message = `DEVIS : ${totalCost} € · QUANTITE : ${totalItem}`;
    document.getElementById('res-name').innerHTML = message;
    document.getElementById('res-name-m').innerHTML = message;
  }
  
  // Get the input element
  const inputPrice = document.getElementById('priceItem');
  
  // Attach the event listener to the input element
 // recupererPrix(inputPrice);
  
  
  /**
   * Fonction qui permet de calculer le prix total
   */
  function calculPanier() {
  
    // Get all color quantity inputs and delete buttons
    const colorAdd = document.querySelectorAll("#print input.quantite");
    const btnSupp = document.querySelectorAll('#print .btnSupprimer');
  
    // Calculate the total price
    let sum = 0;
    colorAdd.forEach(quantite => {
      sum += parseInt(quantite.value);
    });
    panier(sum);
  
    // Add event listeners to update the total price
    colorAdd.forEach(quantite => {
      quantite.addEventListener('input', () => {
        sum = 0;
        colorAdd.forEach(quantite => {
          sum += parseInt(quantite.value);
          panier(sum);
        });
      });
    });
  
    // Add event listeners to delete buttons
    btnSupp.forEach(btn => {
      btn.addEventListener('click', () => {
        const valeurSupp = btn.parentNode.querySelector('input');
        sum -= parseInt(valeurSupp.value);
        panier(sum);
      });
    });
  
  
    // supp item
    suppItem(btnSupp);
    // next input
    enterInput(colorAdd);

   
  
  }


  function appPanier(zone) {


    // Déclechement du calcul du panier
    function handleColumnClick(event) {
      calculPanier();
    }
    
    // calcul desktop
    zone.addEventListener("mouseup", handleColumnClick);
    
    // calcul mobile
    zone.addEventListener("touchstart", handleColumnClick);
    }

  export { handleInputChange, panier, displayErrorMessage, displayTotalItems, calculPanier, appPanier };