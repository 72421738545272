export default [
  {
    "hex": "#F4E593",
    "name": "SMASH137´S POTATO",
    "ref": "BLK 1005",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#FFE668",
    "name": "EASTER YELLOW",
    "ref": "BLK 1010",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#FFDA15",
    "name": "KICKING YELLOW",
    "ref": "BLK 1025",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#FCC000",
    "name": "YELLOW",
    "ref": "BLK 1030",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#F59E01",
    "name": "MELON YELLOW",
    "ref": "BLK 1045",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#D37E0A",
    "name": "TOPAZ",
    "ref": "BLK 1050",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#A34C2E",
    "name": "HAZLE",
    "ref": "BLK 1060",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#6F2F23",
    "name": "PECAN NUT",
    "ref": "BLK 1070",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#612316",
    "name": "MAROON",
    "ref": "BLK 1080",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#E0A600",
    "name": "INDIAN SPICE",
    "ref": "BLK 1110",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#B69822",
    "name": "MASALA",
    "ref": "BLK 1120",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#A18114",
    "name": "DELHI",
    "ref": "BLK 1130",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#6F592A",
    "name": "BOMBAY",
    "ref": "BLK 1140",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#F49F24",
    "name": "JUICE",
    "ref": "BLK 2060",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#EF800D",
    "name": "CLOCKWORK ORANGE",
    "ref": "BLK 2070",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#EA640C",
    "name": "PURE ORANGE",
    "ref": "BLK 2075",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#E85011",
    "name": "HALLOWEEN",
    "ref": "BLK 2085",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#E3001B",
    "name": "CODE RED",
    "ref": "BLK 2093",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#F08C48",
    "name": "TOMORROW",
    "ref": "BLK 2110",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#EB6A27",
    "name": "ATOM´S MEGABLAST",
    "ref": "BLK 2120",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#C4091C",
    "name": "FIRE ROSE",
    "ref": "BLK 3020",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#6D0827",
    "name": "CARDINAL",
    "ref": "BLK 3062",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#3F2224",
    "name": "MERLOT",
    "ref": "BLK 3065",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#331028",
    "name": "CHERRY",
    "ref": "BLK 3070",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#510029",
    "name": "WINEGUM",
    "ref": "BLK 3080",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#F5B8C5",
    "name": "MISS PIGGY",
    "ref": "BLK 3100",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#EF8EB0",
    "name": "PINK CADILLAC",
    "ref": "BLK 3120",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#E7478A",
    "name": "PINK PANTHER",
    "ref": "BLK 3130",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#C1005E",
    "name": "PUNK PINK",
    "ref": "BLK 3145",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#E94190",
    "name": "BEAST",
    "ref": "BLK 3148",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#AA0073",
    "name": "FREAK",
    "ref": "BLK 3150",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#981569",
    "name": "PURPLE RAIN",
    "ref": "BLK 3155",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#AD0F6C",
    "name": "GOOD TIMES",
    "ref": "BLK 3160",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#7E154E",
    "name": "IMPERATOR",
    "ref": "BLK 3170",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#F7C0B7",
    "name": "COCKTAIL",
    "ref": "BLK 3200",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#F29A84",
    "name": "MR. CRAB",
    "ref": "BLK 3220",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#EE8363",
    "name": "SUSHI",
    "ref": "BLK 3240",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#F3A7B1",
    "name": "PATPONG",
    "ref": "BLK 3305",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#EB727B",
    "name": "PINK LEMONADE",
    "ref": "BLK 3310",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#E74963",
    "name": "LOLLIPOP",
    "ref": "BLK 3320",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#BA0F3E",
    "name": "BLOODY MARY",
    "ref": "BLK 3330",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#A8438F",
    "name": "ILLUSION",
    "ref": "BLK 3920",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#9D197E",
    "name": "MAGIC",
    "ref": "BLK 3940",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#C59FC6",
    "name": "BUBBLE BATH",
    "ref": "BLK 3995",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#B68FBC",
    "name": "MS. JACKSON",
    "ref": "BLK 4000",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#8C4A91",
    "name": "MONSTER",
    "ref": "BLK 4020",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#6D2C7E",
    "name": "PIMP VIOLET",
    "ref": "BLK 4040",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#511B57",
    "name": "GALAXY",
    "ref": "BLK 4060",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#B1A0CA",
    "name": "LAVENDER",
    "ref": "BLK 4115",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#A98DBD",
    "name": "BLUE VELVET",
    "ref": "BLK 4130",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#62579B",
    "name": "ROYAL PURPLE",
    "ref": "BLK 4155",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#554189",
    "name": "WIZARD",
    "ref": "BLK 4160",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#0C0940",
    "name": "UNIVERSE",
    "ref": "BLK 4182",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#AA959C",
    "name": "GUT",
    "ref": "BLK 4210",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#836976",
    "name": "KIDNEY",
    "ref": "BLK 4230",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#664B5C",
    "name": "LIVER",
    "ref": "BLK 4250",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#DBB4C6",
    "name": "DUMMY",
    "ref": "BLK 4260",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#986D81",
    "name": "PLUM",
    "ref": "BLK 4280",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#5B2C40",
    "name": "AMETHYST",
    "ref": "BLK 4290",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#CDD2DB",
    "name": "EDELGARD",
    "ref": "BLK 4310",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#A2B0CE",
    "name": "BRUNHILDE",
    "ref": "BLK 4320",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#6F89BC",
    "name": "WALTRAUT",
    "ref": "BLK 4330",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#4D599A",
    "name": "IRMGARD",
    "ref": "BLK 4340",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#6BC8E7",
    "name": "BABY BLUE",
    "ref": "BLK 5020",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#00A2D3",
    "name": "LIGHT BLUE",
    "ref": "BLK 5030",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#0070B8",
    "name": "HORIZON",
    "ref": "BLK 5070",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#054B90",
    "name": "ROYALE BLUE",
    "ref": "BLK 5077",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#00326D",
    "name": "ULTRAMARINE",
    "ref": "BLK 5080",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#00173A",
    "name": "DARK INDIGO",
    "ref": "BLK 5092",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#AEC2C0",
    "name": "DOVE",
    "ref": "BLK 5125",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#52676C",
    "name": "SEAL",
    "ref": "BLK 5140",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#364958",
    "name": "SPACE",
    "ref": "BLK 5160",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#98B0C6",
    "name": "TROUT",
    "ref": "BLK 5180",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#577492",
    "name": "MARLIN",
    "ref": "BLK 5185",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#264B68",
    "name": "WHALE",
    "ref": "BLK 5190",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#00A0B0",
    "name": "ICE BLUE",
    "ref": "BLK 5200",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#97C4E1",
    "name": "LENOR",
    "ref": "BLK 5210",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#5F97C6",
    "name": "BLUE LAGOON",
    "ref": "BLK 5230",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#00579E",
    "name": "KNOCK OUT BLUE",
    "ref": "BLK 5250",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#24327D",
    "name": "SORRENTO BLUE",
    "ref": "BLK 5270",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#E3DE2A",
    "name": "PISTACHIO",
    "ref": "BLK 6000",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#C9D215",
    "name": "ACID",
    "ref": "BLK 6005",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#A9C707",
    "name": "SLIMER",
    "ref": "BLK 6010",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#A1A324",
    "name": "OASIS",
    "ref": "BLK 6012",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#CBD56C",
    "name": "SPRING",
    "ref": "BLK 6013",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#B5CC22",
    "name": "WILD LIME",
    "ref": "BLK 6015",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#51AB27",
    "name": "IRISH GREEN",
    "ref": "BLK 6045",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#008132",
    "name": "BOSTON",
    "ref": "BLK 6055",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#025A27",
    "name": "CELTIC",
    "ref": "BLK 6060",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#003613",
    "name": "BANKNOTE",
    "ref": "BLK 6065",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#003C1A",
    "name": "TAG GREEN",
    "ref": "BLK 6070",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#28C842",
    "name": "HIPPIE",
    "ref": "BLK 6075",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#29B419",
    "name": "MESCALINE",
    "ref": "BLK 6080",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#25900C",
    "name": "WOODSTOCK",
    "ref": "BLK 6090",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#00752D",
    "name": "PLANT",
    "ref": "BLK 6095",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#91D1DA",
    "name": "TIFFANY",
    "ref": "BLK 6110",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#4AADC4",
    "name": "COOL COLOGNE",
    "ref": "BLK 6130",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#0086A1",
    "name": "MERMAID",
    "ref": "BLK 6150",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#013C4A",
    "name": "NEPTUNE",
    "ref": "BLK 6170",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#002A32",
    "name": "RIFFS",
    "ref": "BLK 6175",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#6BDBCF",
    "name": "DROPS",
    "ref": "BLK 6180",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#3DC6AC",
    "name": "NAPPIES",
    "ref": "BLK 6190",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#0C8E76",
    "name": "SURGERY",
    "ref": "BLK 6195",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#9AC776",
    "name": "E2E GREEN",
    "ref": "BLK 6210",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#50AE58",
    "name": "REVOLT GREEN",
    "ref": "BLK 6220",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#007F64",
    "name": "ATLANTIS",
    "ref": "BLK 6240",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#C7DBC8",
    "name": "OLYMP",
    "ref": "BLK 6310",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#B0D7BE",
    "name": "HOPE",
    "ref": "BLK 6320",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#64B699",
    "name": "PATINA",
    "ref": "BLK 6330",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#007764",
    "name": "COPPER GREEN",
    "ref": "BLK 6340",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#D1D5BC",
    "name": "TRABI",
    "ref": "BLK 6410",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#BEC695",
    "name": "BEETLE",
    "ref": "BLK 6420",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#A3AC7C",
    "name": "RESEDA",
    "ref": "BLK 6430",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#A5B29C",
    "name": "FOG",
    "ref": "BLK 6510",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#8C9C7D",
    "name": "MIST",
    "ref": "BLK 6520",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#4C6043",
    "name": "STORM",
    "ref": "BLK 6530",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#D1C782",
    "name": "BAMBOO",
    "ref": "BLK 6610",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#A3903F",
    "name": "HEMP",
    "ref": "BLK 6620",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#8F8534",
    "name": "BOA",
    "ref": "BLK 6625",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#705A39",
    "name": "PAN",
    "ref": "BLK 6630",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#BAB526",
    "name": "PEAR GREEN",
    "ref": "BLK 6710",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#718428",
    "name": "LOST ISLAND",
    "ref": "BLK 6720",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#565B21",
    "name": "TROOPS",
    "ref": "BLK 6725",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#28401C",
    "name": "TOAD",
    "ref": "BLK 6730",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#D6D1AF",
    "name": "FACE",
    "ref": "BLK 6905",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#A2A682",
    "name": "HANNIBAL",
    "ref": "BLK 6910",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#808460",
    "name": "MURDOCK",
    "ref": "BLK 6920",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#3F3E2A",
    "name": "B.A BOSKO",
    "ref": "BLK 6930",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#EEF1E6",
    "name": "JAWS",
    "ref": "BLK 7010",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#C7C8B8",
    "name": "MOUSE",
    "ref": "BLK 7030",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#999A92",
    "name": "SHARK",
    "ref": "BLK 7050",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#5E6460",
    "name": "RHINO",
    "ref": "BLK 7070",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#48403D",
    "name": "ANT",
    "ref": "BLK 7080",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#C7BBA8",
    "name": "GAMBETTA",
    "ref": "BLK 7110",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#9D9283",
    "name": "LENNOX",
    "ref": "BLK 7120",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#7D7268",
    "name": "LAMBRATE",
    "ref": "BLK 7130",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#524038",
    "name": "INDUSTRIILOR",
    "ref": "BLK 7140",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#A69FA2",
    "name": "HOUDINI",
    "ref": "BLK 7210",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#847C82",
    "name": "MORPHEUS",
    "ref": "BLK 7220",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#57515A",
    "name": "NOSTRADAMUS",
    "ref": "BLK 7230",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#A89E9F",
    "name": "GHETTO",
    "ref": "BLK 7320",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#645B5C",
    "name": "DUMBO",
    "ref": "BLK 7340",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#352C2D",
    "name": "SLATE",
    "ref": "BLK 7360",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#F2E8C4",
    "name": "IVORY",
    "ref": "BLK 8000",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#B89247",
    "name": "SAND",
    "ref": "BLK 8010",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#D9B980",
    "name": "BEIGE",
    "ref": "BLK 8020",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#DAAC92",
    "name": "ICED COFFE",
    "ref": "BLK 8030",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#CB916C",
    "name": "CREMINO",
    "ref": "BLK 8040",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#B27A59",
    "name": "FRAPPÉ",
    "ref": "BLK 8050",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#7C5035",
    "name": "CHOCOLATE",
    "ref": "BLK 8060",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#49201A",
    "name": "JAWA",
    "ref": "BLK 8070",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#902C2E",
    "name": "RUST",
    "ref": "BLK 8080",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#C2A875",
    "name": "ARABIAN",
    "ref": "BLK 8110",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#AB9269",
    "name": "GOBI",
    "ref": "BLK 8120",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#90754F",
    "name": "SYRIAN",
    "ref": "BLK 8130",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#9F6B5A",
    "name": "AFTER",
    "ref": "BLK 8210",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#F5AC8C",
    "name": "SNAIL",
    "ref": "BLK 8220",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#DF5105",
    "name": "KOI",
    "ref": "BLK 8230",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#B94709",
    "name": "FALLING",
    "ref": "BLK 8240",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#5B2607",
    "name": "CANDY BAR",
    "ref": "BLK 8250",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#512320",
    "name": "MUD",
    "ref": "BLK 8260",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#030506",
    "name": "BLACK",
    "ref": "BLK 9001",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#FFFFFF",
    "name": "SNOW WHITE",
    "ref": "BLK 9100",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#FBFCF6",
    "name": "WHITE",
    "ref": "BLK 9105",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#F9E900",
    "name": "TRUE YELLOW",
    "ref": "BLK TR 1000",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#FFF562",
    "name": "TRUE YEL. 50%",
    "ref": "BLK TR 1010",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#E51175",
    "name": "TRUE MAGENTA",
    "ref": "BLK TR 4000",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#E75D98",
    "name": "TRUE MAG. 50%",
    "ref": "BLK TR 4010",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#00B8D2",
    "name": "TRUE CYAN",
    "ref": "BLK TR 5000",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#86CEDC",
    "name": "TRUE CYAN 50%",
    "ref": "BLK TR 5010",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#FFDF00",
    "name": "POWER YELLOW",
    "ref": "P1000",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#FF5A10",
    "name": "POWER ORANGE",
    "ref": "P2000",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#E22218",
    "name": "POWER RED",
    "ref": "P3000",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#E64690",
    "name": "POWER PINK",
    "ref": "P4000",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#18225B",
    "name": "POWER VIOLET",
    "ref": "P4100",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#00549D",
    "name": "POWER BLUE",
    "ref": "P5000",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#67B028",
    "name": "POWER GREEN",
    "ref": "P6000",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#C0C0C0",
    "name": "OUTLINE SILVER",
    "ref": "BLK SilverOutline",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#806031",
    "name": "COPPERCHROME",
    "ref": "BLK CHROME-1",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#826107",
    "name": "GOLDCHROME",
    "ref": "BLK CHROME-2",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  },
  {
    "hex": "#999999",
    "name": "SILVERCHROME",
    "ref": "BLK CHROME-3",
    "marque": "montana black",
    "nuancier": "colorMontanaBlack"
  }
]