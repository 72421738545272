/**
 * END TO END COLOR - version 2
 * @author	JuReyms   <contact@lafermegrahique.fr>
 * 
 */


/** CSS */

import './css/style.css'

/** Librairy */
import Sortable from 'sortablejs';
import anime from 'animejs'


// Data
import colorsMolotow from './data/molotow-colors.json.js';
import navMolotow from './data/molotow-nav.json.js';
import colorMontana from './data/montana-94.json.js';
import navMontana from './data/montana-94-nav.json.js';
import colorMontanaBlack from './data/montana-black.json.js';
import navMontanaBlack from './data/montana-black-nav.json.js';
import montanaHardcore from './data/montana-hardcore.json.js';
import montanaHardcoreNav from './data/montana-hardcore-nav.json.js';
import montanaBased from './data/montana-based.json.js';
import montanaBasedNav from './data/montana-based-nav.json.js';
import flameBlue from './data/fame-blue.json.js';
import flameBlueNav from './data/flame-blue-nav.json.js';
import flameOrange from './data/flame-orange.json.js';
import montanaGold from './data/montana-gold.json.js';
import montanaGoldNav from './data/montana-gold-nav.json.js';


// Modules JS
import { Notifications } from './modules/notifs.js';
import { appPanier } from './modules/panier.js';
import { resizeDiv } from './modules/resize.js';
import { suppAllItems, helpToggle } from './modules/fonctions.js';
import { generateColorPalette, refreshColorPalette } from './modules/colorsGeneratePalette.js';
import { printSelection } from './modules/exportPrint.js';
import { appStorage, saveStorageAuto } from './modules/storage.js';
import { colorsSimilares } from './modules/colorsCompare.js';
import { infosColor } from './modules/colorsInfosPage.js';
import { infosColorBox } from './modules/colorsInfosBox';
import { appNuanciers } from './modules/nuanciers.js';
import { socialShareImg } from './modules/exportSocial.js';
import { exportJpg } from './modules/exportJpg.js';
import { createNewPage } from './modules/createNewPage.js';
import { hexToRgbString, hexToCmykString, rgbToCmyk } from "./modules/colorsConvert.js";
import { closePropositions } from "./modules/fonctions.js";




/* Images VueJS*/
import imgMolotow from './img/molotow.png';
import imgMolotowMini from './img/molotow-n.png';
import imgMontana94 from './img/montana 94.png';
import imgMontana94Mini from './img/montana 94-n.png';
import imgMontanaBlack from './img/montana black.png';
import imgMontanaBlackMini from './img/montana black-n.png';
import imgMontanaHardcore from './img/montana hardcore.png';
import imgMontanaHardcoreMini from './img/montana hardcore-n.png';
import imgMontanaBased from './img/montana water based.png';
import imgMontanaBasedMini from './img/montana water based-n.png';
import imgFlameOrange from './img/flame orange.png';
import imgFlameOrangeMini from './img/flame orange-n.png';
import imgFlameBlue from './img/flame blue.png';
import imgFlameBlueMini from './img/flame blue-n.png';
import imgMontanaGold from './img/montana gold.png';
import imgMontanaGoldMini from './img/montana gold-n.png';

import imgSave from './img/save2.png';



/**
 * VueJS
 */

const app = Vue.createApp({
  data() {
    return {
      //app
      versionApp: '2.3.1',
      //defaut
      brand: 'Molotow',
      nuancier: colorsMolotow,
      navColor: navMolotow,
      imgNuancier: imgMolotow,
      logoNuancier: imgMolotowMini,
      // nuanciers
      nuancierSets: {
        molotow: {
          id: 'molotow',
          brand: 'Molotow',
          nuancier: colorsMolotow,
          navColor: navMolotow,
          imgNuancier: imgMolotow,
          logoNuancier: imgMolotowMini,
        },
        montana94: {
          id: 'montana94',
          brand: 'Montana 94',
          nuancier: colorMontana,
          navColor: navMontana,
          imgNuancier: imgMontana94,
          logoNuancier: imgMontana94Mini
        },
        montanaBlack: {
          id: 'montanaBlack',
          brand: 'Montana Cans - Black',
          nuancier: colorMontanaBlack,
          navColor: navMontanaBlack,
          imgNuancier: imgMontanaBlack,
          logoNuancier: imgMontanaBlackMini
        },
        montanaHardcore: {
          id: 'montanaHardcore',

          brand: 'Montana Hardcore',
          nuancier: montanaHardcore,
          navColor: montanaHardcoreNav,
          imgNuancier: imgMontanaHardcore,
          logoNuancier: imgMontanaHardcoreMini
        },
        montanaBased: {
          id:'montanaBased',
          brand: 'Montana Water Based',
          nuancier: montanaBased,
          navColor: montanaBasedNav,
          imgNuancier: imgMontanaBased,
          logoNuancier: imgMontanaBasedMini
        },
       /* flameOrange: {
          id: 'flameOrange',
          brand: 'Flame Orange',
          nuancier: flameOrange,
          //navColor: fameOrangeNav,
          imgNuancier: imgFlameOrange,
          logoNuancier: imgFlameOrangeMini
        },*/
        flameBlue: {
          id: 'flameBlue',
          brand: 'Flame Blue',
          nuancier: flameBlue,
          navColor: flameBlueNav,
          imgNuancier: imgFlameBlue,
          logoNuancier: imgFlameBlueMini
        },
        montanaGold: {
          id:'montanaGold',
          brand: 'Montana Gold',
          nuancier: montanaGold,
          navColor: montanaGoldNav,
          imgNuancier: imgMontanaGold,
          logoNuancier: imgMontanaGoldMini
        }
      },
    }
  },
  methods: {
    // Nuanciers
    countItems(nuancier) {
      return nuancier.length;
    },
    setColorSet(name) {
      const colorSet = this.nuancierSets[name];
      this.nuancier = colorSet.nuancier;
      this.brand = colorSet.brand;
      this.navColor = colorSet.navColor;
      this.imgNuancier = colorSet.imgNuancier;
      this.logoNuancier = colorSet.logoNuancier;
    },
    clickColorSet(name) {
      this.setColorSet(name);
    },
    cloneItem(e) {
      const cloneItem = e.target.parentNode.cloneNode(true);
      document.getElementById('print').prepend(cloneItem);
      const notification = new Notifications('Couleur ajoutée à la selection', '#6bb66c')
    },
    cloneItem2(e) {
      const cloneItem = e.target.parentNode.parentNode.cloneNode(true);
      document.getElementById('print').prepend(cloneItem);
      const notification = new Notifications('Couleur ajoutée à la selection', '#6bb66c')
    },
    infosColor(event, hex, ref) {
      const parentDiv = event.target.parentNode.parentNode.parentNode
      let colorSelectRgb = hexToRgbString(hex); // [255, 0, 0] en rgb
      let colorSelectCmyk = hexToCmykString(hex); // [0, 1, 1, 0] en cmyk

      //scroll top
      location.hash = ref; 

       //effacer toutes les palettes deja existante
       const allBoxInfos = document.querySelectorAll('.sectionSimilaire, .sectionPalette, .sectionInfos');
       for (let i = 0; i < allBoxInfos.length; i++) {
        allBoxInfos[i].remove();
       }

      const insertHtml = `
          <h2 class="titleSimilare">Informations<button class=" btnApp btncloseInfos"></button></h2>
          <div class="colorBoxInfos" style="background-color:${hex}">
              <h3><b>HEX :</b> ${hex} </h3>
              <h3><b>RGB :</b> ${colorSelectRgb[0]}, ${colorSelectRgb[1]}, ${colorSelectRgb[2]} </h3>
              <h3><b>CMYK :</b> C${colorSelectCmyk[0]} M${colorSelectCmyk[1]}  Y${colorSelectCmyk[2]}  K${colorSelectCmyk[3]}  </h3>
          </div>
          `;

      // Create the new section form
      const newDiv = document.createElement('section');
      newDiv.innerHTML = insertHtml;
      newDiv.classList.add('sectionInfos', 'draggable', 'animate__animated', 'animate__fadeIn');
      newDiv.setAttribute('id', 'draggable')
      newDiv.style.display = 'block';

      // Add the new forms to the DOM
      parentDiv.insertAdjacentElement('afterend', newDiv);


      // Récupérer la section contenant les éléments sectionInfos
      const sectionInfos = document.querySelectorAll('#block-colors .sectionInfos');
      // Récupérer tous les éléments ayant la classe "btncloseInfos"
      const closeBtns = document.querySelectorAll('#block-colors .btncloseInfos');
      // Cacher la section contenant les éléments similaires - import
      closePropositions(sectionInfos, closeBtns);

    }

  }
});
app.mount('#app');



/**
 * VANILLA
 */


/* Colonne de couleur selectionné */
const itemsZone = document.querySelector('#print');

// section qui permet la redimenction des div
const zoneResize = document.getElementById('zoneResize');

// div à redimentionner
const blockColors = document.getElementById('block-colors');
const blockSelect = document.getElementById('block-select');

// informations des couleurs - popup
//const btnInfosColors = document.querySelectorAll('.color-infos-form');

// Tableau comprenant tous les nuanciers
const allNuanciers = [
  ...colorsMolotow,
  ...colorMontana,
  ...montanaHardcore,
  ...colorMontanaBlack,
  ...montanaGold,
  ...flameOrange,
  ...flameBlue,
  ...montanaBased
];




// SORTABLE.JS // Fonction qui d'organiser les items avec la librairie sortable.js
new Sortable(itemsZone, {
  handle: '.handle', // handle's class
  animation: 150
});


// Affiche l'aide de l'application
helpToggle();

// Fonctions pour redimensionner les div
resizeDiv(zoneResize, blockColors, blockSelect);

// affichage des nuanciers
appNuanciers();

// informations des couleurs - popup
//infosColor(btnInfosColors);
//infosColorBox();

//recherche de couleurs similaires
const arrayImgNuancierMini = [
  { 'nuancier': 'molotow', 'img': imgMolotowMini },
  { 'nuancier': 'montana 94', 'img': imgMontana94Mini },
  { 'nuancier': 'flame blue', 'img': imgFlameBlueMini },
  { 'nuancier': 'montana hardcore', 'img': imgMontanaHardcoreMini },
  { 'nuancier': 'montana black', 'img': imgMontanaBlackMini },
  { 'nuancier': 'montana water based', 'img': imgMontanaBasedMini },
  { 'nuancier': 'montana gold', 'img': imgMontanaGoldMini },
  { 'nuancier': 'flame orange', 'img': imgFlameOrangeMini },
]
colorsSimilares(allNuanciers, arrayImgNuancierMini);

//  Générateur de palettes de couleurs
generateColorPalette(allNuanciers, arrayImgNuancierMini);
//refreshColorPalette(allNuanciers, arrayImgNuancierMini)
// App de calcul du panier
appPanier(itemsZone);

//Supprimer tous les items selectionnés
suppAllItems(itemsZone);

//Gestion des sauveguardes
appStorage(itemsZone);
setInterval(function () {
  saveStorageAuto(itemsZone);
  //const img = `<img src='${imgSave}'style='height: 15px; width: 15px;  position: absolute; top:0px; right:15px;'/>`;
  //const notifSuppStorage = new Notifications(img, "");
}, 2000);


//Partage - social share img 
socialShareImg(itemsZone);

// Partage - Exporter en jpg 
exportJpg(itemsZone);

// Partage - Exporter en pdf
printSelection();



// bouton infos Couleurs clone
document.addEventListener('click', (e) => {
  const btnInfosColorsSelect = document.querySelectorAll('#print .color-infos-form');
  btnInfosColorsSelect.forEach(element => {
    element.addEventListener('submit', (e) => {
      // Empêcher la soumission du formulaire
      e.preventDefault();
    })
  })
})


// Nav 
/*const btnHelp = document.querySelector('.btnHelp');
const helper = document.querySelector('.helper').innerHTML;

btnHelp.addEventListener('click', createNewPage(helper));*/

/**
 * ANIME JS
 */
const divMouve = document.querySelector('.color-pick-m ');
const animeDiv1 = anime.timeline({ autoplay: true })
  .add({
    targets: divMouve,
    delay: 4000,
    translateY: '-10px',
    duration: 1000,
    easing: 'easeOutBack'
  })
  .add({
    targets: divMouve,
    translateY: '10px',
    duration: 1000,
    easing: 'easeOutBack'
  })
  .add({
    targets: divMouve,
    translateY: 0,
    duration: 1000,
    easing: 'easeOutBack'
  })

divMouve.addEventListener('click', () => {
  animeDiv1.play();
});