///////////////////PRINT//////////////////////

import { Notifications } from './notifs.js';


/**
 * Affiche la sélection en appelant la fonction 'print' du navigateur
 */
function printSelection() {
  const printer = document.getElementById("printer");
  const loader = document.getElementById("containLoad");

  printer.addEventListener("click", async () => {
    const colorAdd = document.querySelectorAll("#print input.quantite").length;

    if (colorAdd > 0) {     
      loader.style.display = 'block';
      const notifPrint = new Notifications("Chargement de l'aperçu", "#856195");

      const divContents = document.getElementById("print").outerHTML;
      print(divContents);
      loader.style.display = 'none';
    } else {
      const notifNoPrint = new Notifications("Veuillez ajouter au moins une couleur !", "#d80028");
    }         
  });
}





  export { printSelection };