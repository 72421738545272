export default [
    {'ref' :'FB-100', 'hex' : '#f0e082'},
    {'ref' :'FB-210', 'hex' : '#f28749'},
    {'ref' :'FB-310', 'hex' : '#e64461'},
    {'ref' :'FB-408', 'hex' : '#9466a2'},
    {'ref' :'FB-418', 'hex' : '#6166a2'},
    {'ref' :'FB-502', 'hex' : '#70c7e7'},
    {'ref' :'FB-524', 'hex' : '#b5d0ee'},
    {'ref' :'FB-614', 'hex' : '#3398b6'},
    {'ref' :'FB-662', 'hex' : '#8be1b1'},
    {'ref' :'FB-624', 'hex' : '#bac33d'},
    {'ref' :'FB-638', 'hex' : '#bad64a'},
    {'ref' :'FB-702', 'hex' : '#bf9a5c'},
    {'ref' :'FB-808', 'hex' : '#a68a8a'},
    {'ref' :'FB-834', 'hex' : '#b0adac'},
    {'ref' :'FB-904', 'hex' : '#000000'},
    {'ref' :'FB-900', 'hex' : '#ffffff'},
    {'ref' :'FB-1000', 'hex' : '#e50075'},

]