export default [
    {
      "hex": "#f0e082",
      "name": "vanilla",
      "ref": "FO-100",
      "marque": "flame orange"
    },
    {
      "hex": "#fedd3b",
      "name": "zinc yellow",
      "ref": "FO-102",
      "marque": "flame orange"
    },
    {
      "hex": "#fad820",
      "name": "cadmium yellow",
      "ref": "FO-104",
      "marque": "flame orange"
    },
    {
      "hex": "#f5cc04",
      "name": "signal yellow",
      "ref": "FO-106",
      "marque": "flame orange"
    },
    {
      "hex": "#f6bb00",
      "name": "melon yellow",
      "ref": "FO-110",
      "marque": "flame orange"
    }
  ]