export default [
  {
    "hex": "#FFFFBF",
    "name": "Unicorn Yellow",
    "ref": "HRV-252",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#fff386",
    "name": "Beach Yellow",
    "ref": "HRV-222",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#FED792",
    "name": "Cream",
    "ref": "HRV-7",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#fdef46",
    "name": "Party Yellow",
    "ref": "HRV-20",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#ffe100",
    "name": "Light Yellow",
    "ref": "HRV-1021",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#FAB700",
    "name": "Luxor Yellow",
    "ref": "HRV-239",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#f4ca2a",
    "name": "Ganges Yellow",
    "ref": "HRV-11",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#FBCB8C",
    "name": "Atacama Yellow",
    "ref": "HRV-206",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#F9BA57",
    "name": "Peach",
    "ref": "HRV-1017",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#F6A800",
    "name": "Medium Yellow",
    "ref": "HRV-1028",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#E77A19",
    "name": "Mango",
    "ref": "HRV-207",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#B9501A",
    "name": "Mustard",
    "ref": "HRV-8023",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#933B00",
    "name": "Pangea Brown",
    "ref": "HRV-261",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#6D2601",
    "name": "Rust Red",
    "ref": "HRV-18",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#FAC294",
    "name": "Apricot",
    "ref": "HRV-9",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#F6AD6B",
    "name": "Pumpkin",
    "ref": "HRV-208",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#EC753B",
    "name": "Pastel Orange",
    "ref": "HRV-2003",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#E95E0F",
    "name": "Orange",
    "ref": "HRV-2004",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#DC4126",
    "name": "Calcutta Orange",
    "ref": "HRV-209",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#910e14",
    "name": "Prometheus Orange",
    "ref": "HRV-210",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#5E0000",
    "name": "Iroko Red",
    "ref": "HRV-260",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#FF958E",
    "name": "Flamingo",
    "ref": "HRV-259",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#E74F45",
    "name": "Colorado Red",
    "ref": "HRV-33",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#E5352D",
    "name": "Light Red",
    "ref": "HRV-3020",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#C90019",
    "name": "Vivid Red",
    "ref": "HRV-3001",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#E5352D",
    "name": "Madrid Red",
    "ref": "HRV-241",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#8d0000",
    "name": "Soviet Red",
    "ref": "HRV-242",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#82131D",
    "name": "Bordeaux Red",
    "ref": "HRV-3004",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#F4ADC5",
    "name": "Alice Pink",
    "ref": "HRV-348",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#FFA2CA",
    "name": "Manga Pink",
    "ref": "HRV-258",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#EE87AD",
    "name": "Love Pink",
    "ref": "HRV-211",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#DE336B",
    "name": "Erika",
    "ref": "HRV-4003",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#DD1D5A",
    "name": "Magenta",
    "ref": "HRV-4010",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#C50041",
    "name": "Akari Red",
    "ref": "HRV-212",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#762A3D",
    "name": "Merlot Red",
    "ref": "HRV-213",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#F6B8D0",
    "name": "Treze Violet",
    "ref": "HRV-349",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#CA87B6",
    "name": "Princess Violet",
    "ref": "HRV-257",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#C8739F",
    "name": "Witch Violet",
    "ref": "HRV-224",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#C15C92",
    "name": "Geisha Violet",
    "ref": "HRV-225",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#8F154B",
    "name": "Pure Purple",
    "ref": "HRV-350",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#7C3F60",
    "name": "Tube Violet",
    "ref": "HRV-226",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#582741",
    "name": "Cherry",
    "ref": "HRV-4007",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#ACA6D1",
    "name": "Malva",
    "ref": "HRV-256",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#9993C3",
    "name": "Violet",
    "ref": "HRV-214",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#6D63A5",
    "name": "Prophet Violet",
    "ref": "HRV-215",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#574A96",
    "name": "Blue Violet",
    "ref": "HRV-3",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#45347A",
    "name": "Anonymous Violet",
    "ref": "HRV-216",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#2F2280",
    "name": "Galaxy Violet",
    "ref": "HRV-264",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#3E2D65",
    "name": "Vampire Violet",
    "ref": "HRV-27",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#C8D3ED",
    "name": "Aurora Blue",
    "ref": "HRV-73",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#889ed1",
    "name": "Hortensia Blue",
    "ref": "HRV-351",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#5d78ba",
    "name": "Gala Blue",
    "ref": "HRV-352",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#40549f",
    "name": "Julione Blue",
    "ref": "HRV-353",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#574A96",
    "name": "Babylon Blue",
    "ref": "HRV-243",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#40549f",
    "name": "Universe Blue",
    "ref": "HRV-354",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#40549f",
    "name": "Lewis Blue",
    "ref": "HRV-32",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#94BFE5",
    "name": "Costa Brava Blue",
    "ref": "HRV-74",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#6F9FD0",
    "name": "Waimea Blue",
    "ref": "HRV-227",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#4875B3",
    "name": "Zeppelin Blue",
    "ref": "HRV-228",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#465499",
    "name": "Andromeda Blue",
    "ref": "HRV-229",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#37306A",
    "name": "Ultramarine Blue",
    "ref": "HRV-5002",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#0D0F49",
    "name": "Leviathan Blue",
    "ref": "HRV-230",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#020E37",
    "name": "Nostromo Blue",
    "ref": "HRV-75",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#86CEE1",
    "name": "Himalayan Blue",
    "ref": "HRV-13",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#6BDCFF",
    "name": "Arctic Blue",
    "ref": "HRV-29",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#60C5E7",
    "name": "Light Blue",
    "ref": "HRV-8",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#00A2DD",
    "name": "Avatar Blue",
    "ref": "HRV-217",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#007FC4",
    "name": "Electric Blue",
    "ref": "HRV-30",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#00659D",
    "name": "Dark Blue",
    "ref": "HRV-5005",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#004578",
    "name": "Neptune Blue",
    "ref": "HRV-218",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#8ECFD3",
    "name": "Fukushima Blue",
    "ref": "HRV-355",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#1BB2C4",
    "name": "Cousteau Blue",
    "ref": "HRV-231",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#83CCDF",
    "name": "Patagonia Blue",
    "ref": "HRV-255",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#009CAB",
    "name": "Glacier Blue",
    "ref": "HRV-232",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#008098",
    "name": "Planet Blue",
    "ref": "HRV-14",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#006675",
    "name": "Christiania Blue",
    "ref": "HRV-233",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#004750",
    "name": "Indigo Blue",
    "ref": "HRV-234",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#97D2D0",
    "name": "Vintage Blue",
    "ref": "HRV-356",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#6EC3BF",
    "name": "Iceberg Blue",
    "ref": "HRV-357",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#60B3AC",
    "name": "Waterlily Blue",
    "ref": "HRV-358",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#008C8D",
    "name": "Mururoa Blue",
    "ref": "HRV-359",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#007F80",
    "name": "Caribbean Blue",
    "ref": "HRV-5021",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#1F6363",
    "name": "Major Blue",
    "ref": "HRV-360",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#00454E",
    "name": "Ocean Blue",
    "ref": "HRV-5020",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#A6D5C6",
    "name": "Potosi Green",
    "ref": "HRV-361",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#9fe5c2",
    "name": "Max Green",
    "ref": "HRV-254",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#8bc8aa",
    "name": "Paris Green",
    "ref": "HRV-219",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#5bb89e",
    "name": "UFO Green",
    "ref": "HRV-220",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#009473",
    "name": "Surgical Green",
    "ref": "HRV-21",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#008352",
    "name": "Dark Green",
    "ref": "HRV-6016",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#005231",
    "name": "Persephone Green",
    "ref": "HRV-221",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#9CCD9A",
    "name": "Golden Green",
    "ref": "HRV-24",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#74B957",
    "name": "Light Green",
    "ref": "HRV-4",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#88b14f",
    "name": "Guacamole Green",
    "ref": "HRV-34",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#6A9720",
    "name": "Valley Green",
    "ref": "HRV-6018",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#6a8515",
    "name": "Cologno N. Green",
    "ref": "HRV-237",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#005900",
    "name": "Lutecia Green",
    "ref": "HRV-5",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#0F2405",
    "name": "Amazonas Green",
    "ref": "HRV-6009",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#dedd3a",
    "name": "Poison Green",
    "ref": "HRV-235",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#ced50a",
    "name": "Mojito Green",
    "ref": "HRV-236",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#b0c643",
    "name": "Pistachio Green",
    "ref": "HRV-16",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#78BE7A",
    "name": "Mantis Green",
    "ref": "HRV-362",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#60B565",
    "name": "Mint Green",
    "ref": "HRV-272",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#00964F",
    "name": "Natura Green",
    "ref": "HRV-363",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#006942",
    "name": "Reggae Green",
    "ref": "HRV-364",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#CCE0AC",
    "name": "Pale Green",
    "ref": "HRV-6019",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#c5cc8f",
    "name": "Element Green",
    "ref": "HRV-253",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#acb681",
    "name": "Apple Green",
    "ref": "HRV-15",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#8f995b",
    "name": "Rambo Green",
    "ref": "HRV-250",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#727e48",
    "name": "Khaki Green",
    "ref": "HRV-6013",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#60683d",
    "name": "Olive Green",
    "ref": "HRV-6003",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#46502c",
    "name": "Forest Green",
    "ref": "HRV-251",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#eecf99",
    "name": "Druid Brown",
    "ref": "HRV-246",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#deb276",
    "name": "Tepuy Brown",
    "ref": "HRV-247",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#cc9456",
    "name": "Baobab Brown",
    "ref": "HRV-248",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#b0763f",
    "name": "Greyhound Brown",
    "ref": "HRV-249",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#8a5531",
    "name": "Toasted Brown",
    "ref": "HRV-8002",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#4d2200",
    "name": "Chocolate Brown",
    "ref": "HRV-35",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#CCE0AC",
    "name": "Tobacco Brown",
    "ref": "HRV-8017",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#F9F7EA",
    "name": "Puchineli White",
    "ref": "HRV-365",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#F3EFD6",
    "name": "Bone White",
    "ref": "HRV-1013",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#EBE6D6",
    "name": "Arkalis Grey",
    "ref": "HRV-55",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#CABDB0",
    "name": "City Grey",
    "ref": "HRV-57",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#B7A794",
    "name": "Regina Grey",
    "ref": "HRV-42",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#928782",
    "name": "Nazgul Grey",
    "ref": "HRV-40",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#7E7F82",
    "name": "Ripley Grey",
    "ref": "HRV-39",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#878787",
    "name": "Light Grey",
    "ref": "HRV-6",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#C4C5C6",
    "name": "Squatter Grey",
    "ref": "HRV-366",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#7b7b7b",
    "name": "Matter Grey",
    "ref": "HRV-262",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#7B7C7E",
    "name": "Pearl Grey",
    "ref": "HRV-7040",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#616264",
    "name": "Dark Grey",
    "ref": "HRV-7031",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#575757",
    "name": "Sputnik Grey",
    "ref": "HRV-263",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#464648",
    "name": "Anthracite Grey",
    "ref": "HRV-7016",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#FFFFFF",
    "name": "White",
    "ref": "HRV-9010",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#FFFFFF",
    "name": "Satin White",
    "ref": "HRV-Satin-White",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#FFFFFF",
    "name": "Matte White",
    "ref": "HRV-Matte-White",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#000000",
    "name": "Black",
    "ref": "HRV-9011",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#000000",
    "name": "Matte Black",
    "ref": "HRV-Matte-Black",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#000000",
    "name": "Satin Black",
    "ref": "HRV-Satin-Black",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#D3BF8C",
    "name": "Gold",
    "ref": "HRV-Gold",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#C0C0C0",
    "name": "Silver",
    "ref": "HRV-silver",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  },
  {
    "hex": "#93746b",
    "name": "Copper",
    "ref": "HRV-copper",
    "marque": "montana hardcore",
    "nuancier": "montanaHardcore"
  }
]