//création d'une page popup

function createNewPage(data) {
  //create new div
  const page = document.createElement('div');
  page.classList.add('newPage', 'animate__animated', 'animate__slideInRight');
  page.style.display = 'block';
  //create new button close
  const closeButton = document.createElement('button');
  closeButton.classList.add('btnApp', 'btnClosePage');
  // create div for button close
  const divClose = document.createElement('div');
  divClose.classList.add('divClose');
  //insert
  divClose.append(closeButton);
  page.appendChild(divClose);
  page.append(data);
  return page;

}

function insertNewPageBody(data) {
  document.body.appendChild(data);
}


function closeNewPage(buttons, page) {

  buttons.forEach(button => {
    button.addEventListener('click', () => {
      page.style.display = 'none';
    });
  })
}



export { createNewPage, insertNewPageBody, closeNewPage };