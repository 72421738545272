import { hexToRgbString, hexToCmykString, rgbToCmyk } from "./colorsConvert.js";
import { closePropositions } from "./fonctions.js";


// information sur la couleur selectionné
function infosColorBox() {

    const colorInfos = document.querySelectorAll('.color-infos-form');


    colorInfos.forEach(form => {
        form.addEventListener('submit', (e) => {
            // Empêcher la soumission du formulaire
            e.preventDefault();


            //effacer toutes les palettes deja existante
            const deletePalette = document.querySelectorAll('.sectionSimilaire');
            for (let i = 0; i < deletePalette.length; i++) {
                deletePalette[i].remove();
            }

            const deletePalette2 = document.querySelectorAll('.sectionPalette');
            for (let i = 0; i < deletePalette2.length; i++) {
                deletePalette2[i].remove();
            }

            const deletePalette3 = document.querySelectorAll('.sectionInfos');
            for (let i = 0; i < deletePalette3.length; i++) {
                deletePalette3[i].remove();
            }


            // Récupération des données de la couleur sélectionnée par l'utilisateur
            const colorSelectHex = form.querySelector('input[name=color-infos-hex]').value;
            const colorSelectName = form.querySelector('input[name=color-infos-name]').value;
            const colorSelectRef = form.querySelector('input[name=color-infos-ref]').value;
            const colorSelectMarque = form.querySelector('input[name=color-infos-marque]').value;
            const colorSelectLogo = form.querySelector('input[name=color-infos-logo]').value;

            let colorSelectRgb = hexToRgbString(colorSelectHex); // [255, 0, 0] en rgb
            let colorSelectCmyk = hexToCmykString(colorSelectHex); // [0, 1, 1, 0] en cmyk
            //let colorSelectCmyk2 = rgbToCmyk(colorSelectRgb); // [255, 0, 0] en rgb

            function scrollTo(hash) {
                location.hash = "#" + hash;
            }

            scrollTo(colorSelectRef);



            const insertHtml = `
                <div style="background-color:${colorSelectHex}" class="colorBoxInfos">
                        <h3><b>HEX :</b> ${colorSelectHex} </h3>
                        <h3><b>RGB :</b> ${colorSelectRgb[0]}, ${colorSelectRgb[1]}, ${colorSelectRgb[2]} </h3>
                        <h3><b>CMYK :</b> C${colorSelectCmyk[0]} M${colorSelectCmyk[1]}  Y${colorSelectCmyk[2]}  K${colorSelectCmyk[3]}  </h3>
                        

                    </div>`;


            const parentDiv = form.parentNode.parentNode;

            // Create the new section form
            const newDiv = document.createElement('section');
            newDiv.innerHTML = insertHtml;
            newDiv.classList.add('sectionInfos', 'draggable', 'animate__animated', 'animate__fadeIn');
            newDiv.setAttribute('id', 'draggable')
            newDiv.style.display = 'block';


            // Create the new h2 form
            const newTitle = document.createElement('h2');
            newTitle.innerHTML = 'Informations<button class=" btnApp btncloseInfos"></button>';
            newTitle.classList.add('titleSimilare', 'animate__animated', 'animate__fadeIn');

            // Add the new forms to the DOM
            parentDiv.insertAdjacentElement('afterend', newDiv);
            newDiv.insertAdjacentElement('afterbegin', newTitle);



            // Récupérer la section contenant les éléments sectionInfos
            const sectionInfos = document.querySelectorAll('#block-colors .sectionInfos');
            // Récupérer tous les éléments ayant la classe "btncloseInfos"
            const closeBtns = document.querySelectorAll('#block-colors .btncloseInfos');
            // Cacher la section contenant les éléments similaires - import
            closePropositions(sectionInfos, closeBtns);



        })
    })
}

export { infosColorBox };