export default [

    {
        "hex": "#ffe100",
        "ref": "HRV-252"
    },
    {
        "hex": "#E95E0F",
        "ref": "HRV-9"
    },
    {
        "hex": "#C90019",
        "ref": "HRV-260 "
    },
    {
        "hex": "#FFA2CA",
        "ref": "HRV-348 "
    },
    {
        "hex": "#CA87B6",
        "ref": "HRV-349 "
    },
    {
        "hex": "#9993C3",
        "ref": "HRV-256 "
    },
    {
        "hex": "#889ed1",
        "ref": "HRV-73 "
    },
    {
        "hex": "#60C5E7",
        "ref": "HRV-13"
    },
    {
        "hex": "#1BB2C4",
        "ref": "HRV-355"
    },
    {
        "hex": "#60B3AC",
        "ref": "HRV-356"
    },
    {
        "hex": "#8bc8aa",
        "ref": "HRV-361"
    },
    {
        "hex": "#74B957",
        "ref": "HRV-4"
    },
    {
        "hex": "#acb681",
        "ref": "HRV-6019"
    },
    {
        "hex": "#cc9456",
        "ref": "HRV-246"
    },
    {
        "hex": "#7B7C7E",
        "ref": "HRV-365"
    },
    {
        "hex": "#000000",
        "ref": "HRV-9011"
    },
    {
        "hex": "#ffffff",
        "ref": "HRV-9010"
    },




]