/**
 * Class qui permet d'afficher des notifications
 * 
 * @param {*} message 
 * @param {*} color 
 * 
 */


class Notifications {
    constructor(message, color) {
      this.notif = document.querySelector('.notification');
      this.notif.innerHTML = message;
      this.notif.style.backgroundColor = color;
      this.show();
      this.hideAfterDelay();
    }
  
    show() {
      this.notif.style.display = 'block';
    }
  
    hide() {
      this.notif.style.display = 'none';
    }
  
    hideAfterDelay() {
      setTimeout(() => { this.hide(); }, 3000);
    }
  }

  export { Notifications };